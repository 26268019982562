import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";

import { listMenu } from '../../slices/page.slice';

//const menus = ['ecole', 'formations', 'alternance', 'contrat-apprentissage', 'contrat-professionnalisation']
const PageForm = ({ loading, error, onSave, sel_item, show, onClose, ...props }) => {

    const dispatch = useDispatch();
    const [menus, set_menus] = useState([])

    const validationSchema = Yup.object().shape({
        titre: Yup.string().required('Ce champ est obligatoire !'),
        is_publish: Yup.boolean().required('Ce champ est obligatoire !'),
        is_menu: Yup.boolean().required('Ce champ est obligatoire !'),
        target_menu: Yup.string().nullable(),
        menu_order: Yup.number().nullable(),
        is_home: Yup.boolean(),
        is_tarifs: Yup.boolean(),
        is_footer: Yup.boolean(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (sel_item)
            onSave(Object.assign({ ...sel_item }, data))
        else
            onSave(data)
        reset()
    }

    useEffect(() => {
        if (sel_item) {
            setValue('titre', sel_item?.titre)
            setValue('is_publish', sel_item?.is_publish)
            setValue('is_menu', sel_item?.is_menu)
            setValue('target_menu', sel_item?.target_menu)
            setValue('is_home', sel_item?.is_home)
            setValue('is_footer', sel_item?.is_footer)
            setValue('is_tarifs', sel_item?.is_tarifs)
            setValue('menu_order', sel_item?.menu_order)
        } else reset()
    }, [sel_item])

    const load_menus = () => {
        dispatch(listMenu()).unwrap().then(res => {
            if (Array.isArray(res?.menu?.rows)) {
                set_menus(res?.menu?.rows?.filter(el => el?.type == 'dropdown'))
            }
        })
    }
    useEffect(() => {
        load_menus()
    }, [])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    {`${sel_item ? 'Edit Page' : 'Nouvelle Page'}`}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="col-md-12 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Titre</label>
                            <input type="text" {...register("titre")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.titre?.message}</p>
                        </div>

                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Publié</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_publish")}
                            />
                        </div>
                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Page d'Acceuil</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_home")}
                            />
                        </div>
                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Page Tarifs</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_tarifs")}
                            />
                        </div>


                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Inclus-Menu</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_menu")}
                            />
                        </div>
                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Menu</label>
                            <Form.Select {...register("target_menu")}>
                                <option value="" disabled selected>Choisissez une option</option>
                                {
                                    Array.isArray(menus) && menus.map(el => <option value={el?.id}>{el?.label}</option>)
                                }
                            </Form.Select>
                        </div>
                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Menu Order</label>
                            <input type='number' defaultValue={0} className='form-control form-control-sm ' {...register("menu_order")} />
                            <p className="text-danger">{errors.menu_order?.message}</p>
                        </div>


                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Inclus-Footer</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_footer")}
                            />
                        </div>








                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default PageForm;