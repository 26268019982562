import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class CandidatureService {

    list(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/candidature/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/candidature/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    delete(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/candidature/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }



}

export default new CandidatureService()