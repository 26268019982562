import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listOrganisme, createOrganisme, updateOrganisme, deleteOrganisme } from '../../slices/organisme.slice';
import { upload } from '../../slices/page.slice';

import parse from 'html-react-parser';
import OrganismeForm from './OrganismeForm';


const Organismes = () => {


    const { loading, error } = useSelector(state => state.organisme_store);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_item, set_sel_item] = useState(null)
    const [show_form, set_show_form] = useState(false)

    const doSearch = value => {
    }

    const load_data = () => {
        dispatch(listOrganisme()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    const onCreateOrganisme = (data) => {
        if (data) {
            if (data?.sel_file) {
                console.log(data?.sel_file)
                const formData = new FormData();
                formData.append('file', data?.sel_file);

                dispatch(upload(formData)).unwrap().then(res_upload => {
                    if (res_upload?.path) {
                        let tmp = { ...data, logo: res_upload?.path }
                        dispatch(createOrganisme(tmp)).unwrap().then(res => {
                            if (res?.done) {
                                onCloseForm()
                                load_data()
                            }
                        })
                    }
                })
            } else
                dispatch(createOrganisme(data)).unwrap().then(res => {
                    if (res?.done) {
                        onCloseForm()
                        load_data()
                    }
                })
        }
    }

    const onUpdateOrganisme = (data) => {
        if (data) {
            if (data?.sel_file) {
                console.log(data?.sel_file)
                const formData = new FormData();
                formData.append('file', data?.sel_file);

                dispatch(upload(formData)).unwrap().then(res_upload => {
                    if (res_upload?.path) {
                        let tmp = { ...data, logo: res_upload?.path }
                        dispatch(updateOrganisme(tmp)).unwrap().then(res => {
                            if (res?.done) {
                                onCloseForm()
                                load_data()
                            }
                        })
                    }
                })
            } else
                dispatch(updateOrganisme(data)).unwrap().then(res => {
                    if (res?.done) {
                        onCloseForm()
                        load_data()
                    }
                })
        }
    }

    const onDeleteOrganisme = (id) => {
        if (id)
            dispatch(deleteOrganisme(id)).unwrap().then(res => {
                if (res?.done) {
                    onCloseForm()
                    load_data()
                }
            })
    }


    const onShowForm = (item) => {
        set_sel_item(item)
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_sel_item(null)
        set_show_form(false)
    }


    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>

            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'university']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Les Organismes <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>

                            <button className='btn btn-success float-end' onClick={e => onShowForm(null)} ><FontAwesomeIcon icon={['fas', 'plus']} />Nouvelle Organisme</button>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf/Nom  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <OrganismeList lst_order={lst_data} onView={onShowForm} onDelete={onDeleteOrganisme} />
            </div>


            <OrganismeForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onCreate={onCreateOrganisme} onUpdate={onUpdateOrganisme} sel_item={sel_item} />


        </div>
    );
};


const OrganismeList = ({ lst_order, onView, onDelete }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 card my-1'>

                        <div className="row m-0 pt-2 ">

                            <div className="col-md-1">
                                <b className='text-muted fnt-w4  fnt-sm'>Ref</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>#{el?.id}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Nom</b>
                                <p className='txt-secondary mb-1 fnt-sm fnt-w7'>{el?.nom}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>E-mail</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.email}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Tel</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.tel}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Adresse</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w5'>{parse(String(el?.adresse).replace(/(?:\r\n|\r|\n)/g, '<br />'))}</p>
                            </div>

                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Visit-Candidature</b>
                                <Badge bg='danger'>{el?.cand_visit_count}</Badge>
                            </div>



                            <div className="col-md-1 text-end pt-1">
                                {/* <button className='btn btn-sm btn-danger w-auto me-2' onClick={e => onDelete(el?.id)}><FontAwesomeIcon className='me-0' icon={['fas', 'trash']} /></button> */}
                                <button className='btn btn-sm btn-success w-auto ' onClick={e => onView(el)}><FontAwesomeIcon className='me-0' icon={['fas', 'long-arrow-alt-right']} /></button>
                            </div>

                        </div>
                    </div>
                )
            }


        </div>
    );

}
export default Organismes;