import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Badge from 'react-bootstrap/Badge';

const UserDetails = ({ loading, error, show, onClose, sel_item, onEditPass, onEditUser, ...props }) => {



    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark '>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'user']} />
                    </b>
                    {`Profil : #${sel_item?.id}`}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12 dvdr-t-gray pt-2" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                    <div className="row m-0 dvdr-b-gray pb-3">

                        <div className="col-md-12 dvdr-b-gray mb-3 pb-2 px-0 text-end">
                            <button className='btn btn-sm btn-warning me-2' onClick={onEditUser}><FontAwesomeIcon icon={['fas', 'edit']} /> Edit Profile</button>
                            <button className='btn btn-sm btn-warning' onClick={onEditPass}><FontAwesomeIcon icon={['fas', 'lock']} /> Changer MDP</button>
                        </div>


                        <div className="col-md-6 mb-3">
                            <p className='text-muted mb-0 fnt-sm'>Nom</p>
                            <b className='txt-dark fnt-w7  fnt-sm'>{sel_item?.nom || '---'}</b>
                        </div>
                        <div className="col-md-6 mb-3">
                            <p className='text-muted mb-0 fnt-sm'>Prenom</p>
                            <b className='txt-dark fnt-w7  fnt-sm'>{sel_item?.prenom || '---'}</b>
                        </div>

                        <div className="col-md-6 mb-3">
                            <p className='text-muted mb-0 fnt-sm'>E-mail</p>
                            <b className='txt-dark fnt-w7  fnt-sm'>{sel_item?.email || '---'}</b>
                        </div>
                        <div className="col-md-6 mb-3">
                            <p className='text-muted mb-0 fnt-sm d-block'>Role</p>
                            <Badge bg='success'>{sel_item?.role || '---'}</Badge>
                        </div>
                        <div className="col-md-4 mb-3">
                            <p className='text-muted mb-0 fnt-sm'>Tél</p>
                            <b className='txt-dark fnt-w7  fnt-sm'>{sel_item?.tel || '---'}</b>
                        </div>

                        <div className="col-md-6 mb-3">
                            <p className='text-muted mb-0 fnt-sm'>Crée le</p>
                            <b className='txt-dark fnt-w7  fnt-sm'>{sel_item?.createdAt || '---'}</b>
                        </div>



                    </div>


                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default UserDetails;