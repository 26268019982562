import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class OrganismeService {

    list(data) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/organisme/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/organisme/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/organisme/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    delete(id) {
        let token = storage.getToken();
        return axios.get(`${api_url}/adm/organisme/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


}

export default new OrganismeService()