import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import hero_bottom_shape from '../../../assets/img/hero_bottom_shape.svg'
import hero_default_img from '../../../assets/img/hero_default_img1.png'

import LayoutFieldEditor from './FieldEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_file_url } from '../../../config/Params.constant';


const defaultData = {
    title: '<h1><strong class="ql-size-huge" style="color: rgb(250, 176, 41);"> TITRE, </strong> </h1> <h2><span class="ql-size-huge" style="color: rgb(255, 255, 255);"> SLOGANT OU SOUS-TITRE </span> </h2>',
    btn_text: 'LINK',
    btn_link: '#',
    img: hero_default_img,
    style_bg: 'ly-bg-primary',
    style_btn: 'ly-btn-secondary',
    style_img: '',
}

const style_bg_var = ['ly-hero-grad-bg1', 'ly-hero-grad-bg2', 'ly-hero-grad-bg3', 'ly-hero-grad-bg4', 'ly-bg-primary', 'ly-bg-secondary', 'ly-bg-primary-grad', 'ly-bg-secondary-grad', 'ly-bg-white', 'ly-bg-dark', 'ly-bg-gray']
const style_btn_var = ['ly-btn-primary', 'ly-btn-secondary', 'ly-btn-primary ly-bg-primary-grad', 'ly-btn-secondary ly-bg-secondary-grad', 'ly-btn-dark']


const LyHeroBshape = ({ section, onSave, onUpload, onDelete, loading, onEditOrder, index }) => {

    const [section_meta, set_section_meta] = useState(null)

    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);

    const onEditMeta = (field, data) => {
        if (field && section_meta)
            set_section_meta({ ...section_meta, [field]: data })
    }

    const onSubmit = () => {
        if (section && section_meta) {
            onSave({ ...section, meta_data: section_meta })
        }
    }

    const onSubmitUpload = (field) => {
        if (sel_img && section && field) {
            onUpload(sel_img, { ...section, meta_data: section_meta }, field)
        }
    }

    const onImgChange = (e) => {
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));
    }

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])

    return (
        <div className="row m-0 p-0 ">
            <div className="col-md-6 py-1">
                {
                    style_bg_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_bg', el)}></button>)
                }
            </div>
            <div className="col-md-6 text-end">
                <button className='btn btn-sm btn-success me-1' onClick={onSubmit} disabled={loading}>
                    {loading && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="sr-only">Loading...</span></div>}
                    Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} />
                </button>
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

            </div>

            <div className={`row m-0 px-5 position-relative ly-hero-container ${section_meta?.style_bg}`}>

                <div className="col-md-6 d-flex pb-5 ly-z-99">
                    <div className='align-self-center'>
                        <LayoutFieldEditor field={'title'} value={section_meta?.title} onChange={onEditMeta} />
                        {/* <button className='btn btn app-btn-secondary px-5 py-3 mt-5'>Nos Formation</button> */}

                        <div className="col-md-12 pt-2 mt-0 ">
                            <input value={section_meta?.btn_text} onChange={e => onEditMeta('btn_text', e?.target?.value)} class={`form-control content-editor-input d-inline-block fnt-w9 text-center w-auto px-0 mt-1 ${section_meta?.style_btn}`} />
                            {
                                style_btn_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_btn', el)}></button>)
                            }
                            <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_btn', 'ly-btn-hide')}><FontAwesomeIcon icon={['fas', 'eye-slash']} /></button>

                        </div>
                        <div className='col-md-4'>
                            {
                                section_meta?.style_btn != 'ly-btn-hide' &&

                                <div class="input-group">
                                    <label class="input-group-text no-border-rad"><FontAwesomeIcon icon={['fas', 'link']} /></label>
                                    <input class="form-control form-control-sm no-border-rad" type="url" placeholder='http://...' value={section_meta?.btn_link} onChange={e => onEditMeta('btn_link', e?.target?.value)} />
                                </div>
                            }
                        </div>
                    </div>
                </div>


                <div className="col-md-6 text-center position-relative pt-4 ly-z-99">
                    <img src={sel_img_tmp || (section_meta?.img ? `${api_file_url}${section_meta?.img}` : defaultData.img)} width='90%' className='' alt="" />
                    <div className="col-md-6 p-2 position-absolute top-0 start-50 z-ind99">
                        <div className="col-md-12 pb-1 text-start ">
                            <input type="file" accept="image/*,.pdf" onChange={onImgChange} />
                            {sel_img_tmp && sel_img && <button className='btn btn-sm btn-success' onClick={e => onSubmitUpload('img')}><FontAwesomeIcon icon={['fas', 'upload']} /></button>}
                        </div>
                    </div>
                </div>

                <div className="col-md-12 p-0 m-0 position-absolute bottom-0 start-0">
                    <img src={hero_bottom_shape} width='100%' alt="" />
                </div>
            </div>

        </div>
    );
};

export default LyHeroBshape;