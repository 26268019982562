import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { api_file_url } from '../../config/Params.constant';
import Form from 'react-bootstrap/Form';

const OrganismeForm = ({ loading, error, onCreate, onUpdate, sel_item, show, onClose, ...props }) => {

    const validationSchema = Yup.object().shape({
        nom: Yup.string().required('Ce champ est obligatoire !'),
        email: Yup.string().nullable(),
        tel: Yup.string().nullable(),
        adresse: Yup.string().nullable(),
        link_facebook: Yup.string().nullable(),
        link_instagrame: Yup.string().nullable(),
        link_linkedin: Yup.string().nullable(),
        is_publish: Yup.boolean(),


    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const [sel_file, set_sel_file] = useState()

    const onSubmit = data => {
        //console.log(data)
        console.log(sel_item)
        if (sel_item)
            onUpdate(Object.assign({ ...sel_item }, { ...data, sel_file: sel_file }))
        else
            onCreate({ ...data, sel_file: sel_file })
        reset()
    }

    useEffect(() => {
        if (sel_item) {
            setValue('nom', sel_item?.nom)
            setValue('email', sel_item?.email)
            setValue('tel', sel_item?.tel)
            setValue('adresse', sel_item?.adresse)
            setValue('link_facebook', sel_item?.link_facebook)
            setValue('link_instagrame', sel_item?.link_instagrame)
            setValue('link_linkedin', sel_item?.link_linkedin)
            setValue('is_publish', sel_item?.is_publish)
        } else reset()
    }, [sel_item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    {`${sel_item ? 'Edit Organisme' : 'Nouvelle Organisme'}`}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Publié</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_publish")}
                            />
                        </div>
                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">nom</label>
                            <input type="text" {...register("nom")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.nom?.message}</p>
                        </div>
                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">email</label>
                            <input type="text" {...register("email")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.email?.message}</p>
                        </div>
                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">tel</label>
                            <input type="text" {...register("tel")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.tel?.message}</p>
                        </div>
                        <div className="col-md-12 form-group col-12">
                            <label className="fnt-w7 fnt-sm">adresse</label>
                            <textarea rows='3' type="text" {...register("adresse")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.adresse?.message}</p>
                        </div>

                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Lien Facebook</label>
                            <input type="text" {...register("link_facebook")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.link_facebook?.message}</p>
                        </div>
                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Lien Instagram</label>
                            <input type="text" {...register("link_instagrame")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.link_instagrame?.message}</p>
                        </div>
                        <div className="col-md-6 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Lien LinkedIn</label>
                            <input type="text" {...register("link_linkedin")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.link_linkedin?.message}</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="fnt-w7 fnt-sm d-block">Logo</label>
                            <input type="file" onChange={e => set_sel_file(e?.target?.files[0])} className={`form-control form-control-sm`} />
                        </div>
                        {sel_item?.logo &&
                            <div className="col-md-12">
                                <img src={`${api_file_url}${sel_item?.logo}`} width='25%' alt="" />
                            </div>
                        }







                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                    <hr />

                    {sel_item &&
                        <>
                            <h3>Intégration Guide :</h3>

                            <div className="col-md-12 p-3">
                                <h5 className='fnt-w9'>1-Config Domaine</h5>
                                <p>Pointer Votre domaine sur le serveur <kbd>185.124.202.174</kbd></p>
                                <p><b>Note</b> la modification se fait dans la zone DNS <kbd>Record A</kbd></p>
                            </div>

                            <div className="col-md-12 p-3">
                                <h5 className='fnt-w9'>2-Certif SSL</h5>
                                <p>Génerer votre certif ssl et uploader le <b>2</b> fichier <kbd>certif.pem</kbd> et <kbd>key.pem</kbd> dans le server</p>
                                <p><b>Note</b> uploader dans le dossier <kbd>/home/admin/educ-web/ssl</kbd></p>
                            </div>

                            <div className="col-md-12 p-3">
                                <h5 className='fnt-w9'>3-générer le site web</h5>
                                <p><b>3.1-</b>Connecter-vous avec le <b>SSH</b></p>
                                <p><b>Note</b> Vous pouvez utiliser <kbd>Putty</kbd></p>

                                <p><b>3.2-</b>Executer la commande suivante :</p>
                                <p><kbd>bash /home/admin/educ-web/generate-site.sh</kbd></p>
                                <b><kbd className='bg-danger'>assurez-vous d'entrer les bons paramètres SVP</kbd></b>
                            </div>

                            <div className="col-md-12 p-3">
                                <h5 className='fnt-w9'>4-Liée l'Organisme avec le site</h5>
                                <p>Copier/Coller le Public-Token et changer <b>Organisme Name</b>  dans le fichier <kbd>index.html</kbd> du project Front-end Généré .</p>
                                <p><b>Note</b> le projet et placer sous le dossier <kbd>/var/www/</kbd></p>
                                <div className="col-md-12 bg-dark p-3 fnt-sm ">
                                    <code className='text-light'>
                                        {``}<br />
                                        .....<br />
                                        ..<br />
                                        {"<title>Organisme Name</title>"}<br />
                                        window.PUBLIC_TOKEN="{sel_item?.public_token}"<br />
                                        ..<br />
                                        .....<br />
                                        {``}<br />
                                    </code>
                                </div>

                                <p><b>4.2-</b> Changer les logos et favicon qui se trouve dans le dossier front-générer <kbd>/var/www/NOM_SITE/img/logo</kbd></p>
                            </div>
                        </>
                    }

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default OrganismeForm;