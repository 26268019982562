import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listMenu, createMenu, updateMenu, deleteMenu } from '../../../slices/page.slice';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const Menus = ({ show, onClose, ...props }) => {


    const { loading, error } = useSelector(state => state.page_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [lst_pages, set_lst_pages] = useState([])
    const [sel_item, set_sel_item] = useState(null)
    const [show_form, set_show_form] = useState(false)

    const { sel_organisme } = useSelector(state => state.organisme_store);
    useEffect(() => {
        load_data()
    }, [sel_organisme])

    const load_data = () => {
        if (sel_organisme)
            dispatch(listMenu()).unwrap().then(res => {
                if (Array.isArray(res?.menu?.rows)) {
                    set_lst_data(res?.menu?.rows)
                }

                if (Array.isArray(res?.pages?.rows)) {
                    set_lst_pages(res?.pages?.rows)
                }
            })
    }

    const onSaveMenu = (data) => {
        if (sel_item)
            dispatch(updateMenu(data)).unwrap().then(res => {
                if (res?.done) {
                    onCloseForm()
                    load_data()
                }
            })
        else
            dispatch(createMenu({ ...data, order: Array.isArray(lst_data) ? lst_data?.length : 0 })).unwrap().then(res => {
                if (res?.done) {
                    onCloseForm()
                    load_data()
                }
            })
    }

    const onShowForm = (data) => {
        set_sel_item(data)
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_sel_item(null)
        set_show_form(false)
    }

    const onDeleteMenu = (item) => {
        dispatch(deleteMenu(item?.id)).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }

    const onEditOrder = (item, index, value) => {
        if (Array.isArray(lst_data)) {
            if ((value == -1 && index > 0 && index < lst_data.length) || (value == +1 && (+index + +1) < lst_data.length)) {
                let itemI = { ...item, order: (+item?.order + +value) }
                let itemN = { ...lst_data[(+index + +value)], order: (+lst_data[(+index + +value)]?.order + -value) }

                dispatch(updateMenu(itemI)).unwrap().then(res => {
                    if (res?.done) {
                        dispatch(updateMenu(itemN)).unwrap().then(res => {
                            if (res?.done) {
                                load_data()
                            }
                        })
                    }
                })

            }
        }

    }


    useEffect(() => {
        load_data()
    }, [])






    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
                    </b>
                    Menu
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className='col-md-12 p-2'>
                    <div className="row m-0">
                        <div className="col-md-12  p-2 text-end">
                            <button className='btn btn-success' onClick={e => onShowForm(null)} ><FontAwesomeIcon icon={['fas', 'plus']} />Ajouter</button>
                        </div>

                    </div>

                    <div className="row m-0 mt-2">
                        {error &&

                            <div className="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                            </div>
                        }
                    </div>

                    {show_form &&
                        <MenuForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onSave={onSaveMenu} sel_item={sel_item} />
                    }


                    <div className="row m-0 mt-2">
                        <MenuList lst_order={lst_data} onEdit={onShowForm} onDelete={onDeleteMenu} onEditOrder={onEditOrder} />
                    </div>



                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};


const MenuList = ({ lst_order, onEdit, onDelete, onEditOrder }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map((el, index) =>
                    <div className='col-md-12 p-0 card my-1'>

                        <div className="row m-0 pt-2 ">

                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Ref</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>#{el?.id}</p>
                            </div>
                            <div className="col-md-4">
                                <b className='text-muted fnt-w4  fnt-sm'>Label</b>
                                <p className='txt-danger mb-1 fnt-sm fnt-w7'>{el?.label}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Type</b>
                                <p className='txt-secondary mb-1 fnt-sm fnt-w7'>{el?.type}</p>
                            </div>
                            <div className="col-md-4">
                                <b className='text-muted fnt-w4  fnt-sm'>Lien</b>
                                <p className='txt-secondary mb-1 fnt-sm fnt-w7'>{el?.target}</p>
                            </div>

                            <div className="col-md-12 text-end">
                                <button className="btn btn-default" onClick={e => onEdit(el)}><FontAwesomeIcon icon={['fas', 'edit']} /></button>
                                <button className="btn btn-default" onClick={e => onDelete(el)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                                <button className="btn btn-default" onClick={e => onEditOrder(el, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                                <button className="btn btn-default" onClick={e => onEditOrder(el, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>
                            </div>



                        </div>
                    </div>
                )
            }


        </div>
    );

}



const MenuForm = ({ loading, error, onSave, sel_item, show, onClose, ...props }) => {

    const menu_types = ['link', 'dropdown', 'button']
    const validationSchema = Yup.object().shape({
        label: Yup.string().required('Ce champ est obligatoire !'),
        type: Yup.string().required('Ce champ est obligatoire !'),
        target: Yup.string().nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (sel_item)
            onSave(Object.assign({ ...sel_item }, data))
        else
            onSave(data)
        reset()
    }

    useEffect(() => {
        if (sel_item) {
            setValue('label', sel_item?.label)
            setValue('type', sel_item?.type)
            setValue('target', sel_item?.target)
        } else reset()
    }, [sel_item])

    return (

        <>
            <div className="col-md-12 dvdr-t-gray dvdr-b-gray py-2" >
                <h5>
                    {`${sel_item ? 'Edit Menu' : 'Nouvelle Item'}`}

                    <button className='btn btn-default float-end' onClick={onClose}><FontAwesomeIcon icon={['fas', 'times']} /></button>
                </h5>
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
                <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                    <div className="col-md-8 form-group col-12">
                        <label className="fnt-w7 fnt-sm">Label</label>
                        <input type="text" {...register("label")} className={`form-control form-control-sm `} />
                        <p className="text-danger">{errors.label?.message}</p>
                    </div>

                    <div className="form-group col-md-4 mb-2">
                        <label className="fnt-w7 fnt-sm">Type</label>
                        <Form.Select {...register("type")}>
                            <option value="" disabled selected>Choisissez une option</option>
                            {
                                Array.isArray(menu_types) && menu_types.map(el => <option value={el}>{el}</option>)
                            }
                        </Form.Select>
                    </div>

                    <div className="col-md-12 form-group col-12">
                        <label className="fnt-w7 fnt-sm">URL</label>
                        <input type="text" {...register("target")} className={`form-control form-control-sm `} />
                        <p className="text-danger">{errors.target?.message}</p>
                    </div>

                    <div className="col-md-12 text-center" >
                        {loading ?
                            <div className="col-md-12 p-4 text-ctr">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <button type="submit" className="btn btn-dark  mt-1 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                        }
                    </div>

                </form>

            </div>

        </>
    );
};




export default Menus;