import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { api_file_url } from '../../config/Params.constant';

const niv_requis_options = [
    { label: 'sans-bac', value: null },
    { label: 'Bac', value: 'bac' },
    { label: 'Bac +2', value: 'bac+2' },
    { label: 'Bac +3', value: 'bac+3' },
    { label: 'Bac +4', value: 'bac+4' },
    { label: 'Bac +5', value: 'bac+5' },
]

const FormationConfig = ({ formation, onSave, loading, error }) => {

    const [sel_file, set_sel_file] = useState()
    const validationSchema = Yup.object().shape({
        libelle: Yup.string().required('Ce champ est obligatoire !'),
        descriptif_court: Yup.string().nullable(),
        campus: Yup.string().nullable(),
        duree: Yup.string().nullable(),
        certification: Yup.string().nullable(),
        prochaine_session: Yup.string().nullable(),
        pre_requis: Yup.string().nullable(),
        num_rncp: Yup.string().nullable(),
        is_diplome_etat: Yup.boolean().required('Ce champ est obligatoire !'),
        is_france_competence: Yup.boolean().required('Ce champ est obligatoire !'),
        is_alternance: Yup.boolean().required('Ce champ est obligatoire !'),
        is_initial: Yup.boolean().required('Ce champ est obligatoire !'),
        is_rncp: Yup.boolean().required('Ce champ est obligatoire !'),
        is_titrepro: Yup.boolean().required('Ce champ est obligatoire !'),
        is_publish: Yup.boolean().required('Ce champ est obligatoire !'),
        niv_requis: Yup.string().nullable(),
        categorie: Yup.string().nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (formation) {
            onSave({ ...data, id: formation?.id, fiche_formation_file: sel_file })
            reset()
            set_sel_file(null)
        }
    }
    useEffect(() => {
        if (formation) {
            setValue('libelle', formation?.libelle)
            setValue('descriptif_court', formation?.descriptif_court)
            setValue('campus', formation?.campus)
            setValue('duree', formation?.duree)
            setValue('certification', formation?.certification)
            setValue('prochaine_session', formation?.prochaine_session)
            setValue('pre_requis', formation?.pre_requis)
            setValue('num_rncp', formation?.num_rncp)
            setValue('is_diplome_etat', formation?.is_diplome_etat)
            setValue('is_france_competence', formation?.is_france_competence)
            setValue('is_alternance', formation?.is_alternance)
            setValue('is_initial', formation?.is_initial)
            setValue('is_rncp', formation?.is_rncp)
            setValue('is_titrepro', formation?.is_titrepro)
            setValue('is_publish', formation?.is_publish)
            setValue('niv_requis', formation?.niv_requis)
            setValue('categorie', formation?.categorie)
        } else {
            reset()
        }
    }, [formation])

    return (
        <div className="col-md-12 card mb-5" >
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="row m-0 p-2">
                <div className="col-md-12 text-center" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-success w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                    }
                </div>

                <div className="form-group col-md-6 mb-0">
                    <label className="fnt-w7 fnt-sm">Publié</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_publish")}
                    />
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Catégorie</label>
                    <input type="text" {...register("categorie")} className={`form-control form-control-sm `} />
                    <p className="text-danger">{errors.categorie?.message}</p>
                </div>
                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Libelle</label>
                    <input type="text" {...register("libelle")} className={`form-control form-control-sm `} />
                    <p className="text-danger">{errors.libelle?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Descriptif court</label>
                    <input type="text" {...register("descriptif_court")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.descriptif_court?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Campus</label>
                    <input type="text" {...register("campus")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.campus?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Duree</label>
                    <input type="text" {...register("duree")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.duree?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Certification</label>
                    <input type="text" {...register("certification")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.certification?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Prochaine session</label>
                    <input type="text" {...register("prochaine_session")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.prochaine_session?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Pré-Requis (Court)</label>
                    <input type="text" {...register("pre_requis")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.pre_requis?.message}</p>
                </div>

                <div className="form-group col-md-6">
                    <label className="fnt-w7 fnt-sm">Niveaux d'éducation Requis</label>
                    <select {...register("niv_requis")} className={`form-control form-control-sm ${errors.niv_requis ? 'is-invalid ' : 'form-control'}`} >
                        <option disabled selected value=''>Selectioner le niv requis</option>
                        {
                            niv_requis_options.map(el => <option value={el?.value} key={`opt-${el?.value}`}>{el?.label}</option>)
                        }
                    </select>
                    <p className="text-danger">{errors.niv_requis?.message}</p>
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm">Numéro RNCP</label>
                    <input type="text" {...register("num_rncp")} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.num_rncp?.message}</p>
                </div>


                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">Diplome d'etat</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_diplome_etat")}
                    />
                </div>


                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">France compétence</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_france_competence")}
                    />
                </div>

                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">Alternance</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_alternance")}
                    />
                </div>

                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">Initial</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_initial")}
                    />
                </div>

                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">RNCP</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_rncp")}
                    />
                </div>

                <div className="form-group col-md-6 mb-2">
                    <label className="fnt-w7 fnt-sm">Titre-Pro</label>
                    <Form.Check
                        className='ms-3'
                        type="switch"
                        label=""
                        {...register("is_titrepro")}
                    />
                </div>

                <div className="col-md-6 form-group">
                    <label className="fnt-w7 fnt-sm d-block">Fiche Formation(PDF) {formation?.fiche_formation && <a target='_blank' rel="noreferrer" className='float-end' href={`${api_file_url}${formation?.fiche_formation}`}>{formation?.fiche_formation} <FontAwesomeIcon icon={['fas', 'link']} /></a>}</label>
                    <input type="file" onChange={e => set_sel_file(e?.target?.files[0])} className={`form-control form-control-sm`} />
                    <p className="text-danger">{errors.fiche_formation_file?.message}</p>
                </div>










            </form>

        </div>
    );
};

export default FormationConfig;