import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, NavLink, Routes, Route } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import { api_file_url } from '../../config/Params.constant';

const AsideMenu = ({ organisme, user_routes, loggedUser, onLogOut, isOpen, setIsopen }) => {

    return (
        <>
            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>

                <div className="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px", display: "inline-block" }}>
                    <ul className="nav navbar-nav" style={{ flexDirection: "row" }}>

                        <li className="nav-item ">
                            <Dropdown drop="down" className='user-hd-info'>
                                <Dropdown.Toggle variant="default  fnt-largr" className="no-arrow   dvdr-l-gray no-brd-rad ps-3 pe-3" id="dropdown-basic" >
                                    <b className="fnt-w5 ">
                                        <span>
                                            <FontAwesomeIcon icon={['fas', 'user-circle']} />
                                        </span>
                                        <span className="fnt-med ps-2">{`${loggedUser?.email}(${loggedUser?.role})`}</span>
                                    </b>

                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb">
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>



                    </ul>
                </div>


            </nav>

            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12 py-4" style={{ background: '#fff' }}>
                        <a href={`/app`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={`${api_file_url}${organisme?.logo}`} alt="" />
                        </a>
                    </div>



                    <ul className="sidebar-menu mt-4 pt-4">
                        {
                            Array.isArray(user_routes) && user_routes.map(el =>
                                <li key={`navl -${el?.label}`}>
                                    <NavLink to={el?.path} activeClassName="activel" end={el?.exact} >
                                        <span className="icon">
                                            <FontAwesomeIcon icon={['fas', `${el?.icon}`]} />
                                        </span>
                                        <span className={isOpen ? "title" : "hidebal"}>
                                            {el?.label}
                                        </span>

                                    </NavLink>
                                </li>
                            )
                        }


                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={onLogOut}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>

        </>
    );
};

export default AsideMenu;