import React, { useState } from 'react';
import FormationInfoEditor from './FormationInfoEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from "react-redux";
import { createProgramme, updateProgramme, deleteProgramme } from '../../slices/formation.slice';
import ProgrammeForm from './ProgrammeForm';
import FieldEditor from './FieldEditor';
import Table from 'react-bootstrap/Table';

const FormationProgramme = ({ formation, onSave, load_data, loading, error }) => {

    const dispatch = useDispatch();
    const [show_form, set_show_form] = useState(false)
    const [sel_item, set_sel_item] = useState(false)

    const onCreateProgramme = (data) => {
        dispatch(createProgramme({ ...data, formationId: formation?.id })).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }

    const onUpdateProgramme = (data) => {
        dispatch(updateProgramme(data)).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }

    const onDeleteProgramme = (id) => {
        dispatch(deleteProgramme(id)).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }

    const onShowForm = (value) => {
        set_sel_item(value)
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_sel_item(null)
        set_show_form(false)
    }

    return (
        <div className='row m-0 formation-container py-3'>
            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Programme de la formation'}</h4>
                <div className="col-md-12 py-1 text-end">
                    <button className='btn btn-success' onClick={e => onShowForm(null)}><FontAwesomeIcon icon={['fas', 'plus']} /> Ajouter une matiére</button>
                </div>

                <div className="col-md-12 py-2">
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Matière</th>
                                <th>Coef</th>
                                <th>Durée</th>
                                <th>Forme</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(formation?.formation_programmes) && formation?.formation_programmes.map(el =>
                                    <tr>
                                        <td>{el?.libelle}</td>
                                        <td>{el?.coef}</td>
                                        <td>{el?.duree}</td>
                                        <td>{el?.forme}</td>
                                        <td>
                                            <button className='btn btn-sm btn-warning me-1' onClick={e => onShowForm(el)}><FontAwesomeIcon icon={['fas', 'edit']} /></button>
                                            <button className='btn btn-sm btn-danger' onClick={e => onDeleteProgramme(el?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                </div>
                <ProgrammeForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onCreate={onCreateProgramme} onUpdate={onUpdateProgramme} sel_item={sel_item} />
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Blocs de compétences'}</h4>
                <FieldEditor target_field={'competences_attestees'} formation={formation} onSave={onSave} loading={loading} />
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Modalités pédagogiques'}</h4>
                <FieldEditor target_field={'modalite_pedagogiques'} formation={formation} onSave={onSave} loading={loading} />
            </div>

            <div className="col-md-12 formation-info-box mb-5">
                <h4 className='formation-info-box-title'>{'Modalités d\'évaluation'}</h4>
                <FieldEditor target_field={'modalite_evaluation'} formation={formation} onSave={onSave} loading={loading} />
            </div>
        </div>
    );
};

export default FormationProgramme;