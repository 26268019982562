import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class MessageService {

    list(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/messageContact/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/public/messageContact/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/messageContact/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    delete(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/messageContact/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }



}

export default new MessageService()