import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

const FormationQuickForm = ({ loading, error, onCreate, show, onClose, ...props }) => {

    const validationSchema = Yup.object().shape({
        libelle: Yup.string().required('Ce champ est obligatoire !'),
        descriptif_court: Yup.string(),
        is_diplome_etat: Yup.boolean().required('Ce champ est obligatoire !'),
        is_france_competence: Yup.boolean().required('Ce champ est obligatoire !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        onCreate(data)
        reset()
    }

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    Nouvelle Formation
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="col-md-12 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Libelle</label>
                            <input type="text" {...register("libelle")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.libelle?.message}</p>
                        </div>

                        <div className="col-md-12 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Descriptif court</label>
                            <input type="text" {...register("descriptif_court")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.descriptif_court?.message}</p>
                        </div>


                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">Diplome d'etat</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_diplome_etat")}
                            />
                        </div>
                        <div className="form-group col-md-6 mb-2">
                            <label className="fnt-w7 fnt-sm">France compétence</label>
                            <Form.Check
                                className='ms-3'
                                type="switch"
                                id="custom-switch"
                                label=""
                                {...register("is_france_competence")}
                            />
                        </div>







                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default FormationQuickForm;