import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YouTube from 'react-youtube';
import LayoutFieldEditor from './FieldEditor';

const defaultData = {
    videoId: '',
    content: ''
}

const style_bg_var = ['ly-bg-primary', 'ly-bg-secondary', 'ly-bg-primary-grad', 'ly-bg-secondary-grad', 'ly-bg-white', 'ly-bg-dark', 'ly-bg-gray']

const LyVideo = ({ section, onSave, onDelete, loading, onEditOrder, index }) => {

    const [section_meta, set_section_meta] = useState(null)

    const onEditMeta = (field, data) => {
        if (field && section_meta)
            set_section_meta({ ...section_meta, [field]: data })
    }

    const onSubmit = () => {
        if (section && section_meta) {
            onSave({ ...section, meta_data: section_meta })
        }
    }

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])


    return (
        <div className={`row m-0 p-0 brd-b-dashed position-relative ${section_meta?.style_bg}`}>


            <div className="col-md-4 p-2">
                {
                    style_bg_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_bg', el)}></button>)
                }
            </div>

            <div className="col-md-4 py-2 ps-0">
                <input type="text" placeholder='ID Video Youtube...' className='' value={section_meta?.videoId} onChange={e => onEditMeta('videoId', e?.target?.value)} />
            </div>

            <div className="col-md-4 text-end">
                <button className='btn btn-sm btn-success me-1' onClick={onSubmit} disabled={loading}>
                    {loading && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="sr-only">Loading...</span></div>}
                    Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} />
                </button>
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

            </div>

            <div className="col-md-6 text-center">
                <YouTube videoId={section_meta?.videoId} />
            </div>

            <div className="col-md-6">
                <LayoutFieldEditor field={'content'} value={section_meta?.content} onChange={onEditMeta} />
            </div>




        </div>
    );
};



export default LyVideo;