import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import htmlEditButton from "quill-html-edit-button";




const FieldEditor = ({ formation, target_field, onSave, loading }) => {

    // const Font = Quill.import('formats/font');
    // Font.whitelist = ['poppin'];
    // Quill.register(Font, true);

    Quill.register({
        "modules/htmlEditButton": htmlEditButton
    })


    const [value, setValue] = useState('');
    const [is_edit, set_is_edit] = useState(false);
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ["small", false, "large", "huge"] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            [{ 'color': ['#ffffff', '#000000', '#262b40', '#be231f', '#fab029', '#212529', '#198754', '#332984', '#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9', '#9b59b6', '#8e44ad', '#34495e', '#2c3e50', '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#ecf0f1', '#bdc3c7', '#95a5a6', '#7f8c8d',] }, { 'background': ['#ffffff', '#000000', '#262b40', '#be231f', '#fab029', '#212529', '#198754', '#332984', '#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9', '#9b59b6', '#8e44ad', '#34495e', '#2c3e50', '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#ecf0f1', '#bdc3c7', '#95a5a6', '#7f8c8d',] }],          // dropdown with defaults from theme
            // [{ 'font': ['poppin'] }],
            ['clean']
        ],
        htmlEditButton: {},

    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'size', 'code', 'code-block', 'color', 'background', 'align'
    ]

    const onEdit = () => { set_is_edit(true) }

    const onSubmit = () => {
        if (formation && target_field && value) {
            onSave({ id: formation?.id, [target_field]: value })
            set_is_edit(false)
        }
    }

    useEffect(() => {
        if (formation) {
            setValue(formation[target_field])
        }
    }, [formation, target_field])


    return (

        <div className="col-md-12">

            {!is_edit ?
                <div className="row m-0 p-2">
                    <div className="col-md-12 text-end">
                        <button type="button" onClick={onEdit} className="btn btn-warning btn-sm w-auto" ><FontAwesomeIcon className='ms-0' icon={['fas', 'edit']} /></button>
                    </div>
                    {formation && parse(String(formation[target_field]).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                </div>
                :
                <div className="row m-0 py-3">
                    <div className="col-md-12 text-center px-4 py-1" >
                        {loading ?
                            <div className="col-md-12 p-4 text-ctr">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <button type="button" onClick={onSubmit} className="btn btn-success w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                        }
                    </div>
                    <div className="col-md-12">
                        <ReactQuill className='' theme="snow" modules={modules} formats={formats} value={value} onChange={setValue} />
                    </div>

                </div>
            }
        </div>
    );
};

export default FieldEditor;