import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import defaultImage from '../../../assets/img/ic_photo.png'
import LayoutFieldEditor from './FieldEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_file_url } from '../../../config/Params.constant';

const defaultData = {
    title: 'Section Title',
    boxes: [
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
        {
            title: 'Box Title',
            img: defaultImage,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        },
    ]
}
const Layout3ImageTextBox = ({ section, onSave, onUpload, onDelete, loading }) => {

    const [section_meta, set_section_meta] = useState(null)

    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);


    const onEditMeta = (field, data) => {
        if (field && section_meta)
            set_section_meta({ ...section_meta, [field]: data })
    }

    const onSubmit = () => {
        if (section && section_meta) {
            onSave({ ...section, meta_data: section_meta })
        }
    }

    const onSubmitUpload = (field) => {
        if (sel_img && section && field) {
            onUpload(sel_img, { ...section, meta_data: section_meta }, field)
        }
    }

    const onImgChange = (e) => {
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));
    }

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])

    return (
        <div className='row m-0 bg-white p-3 brd-b-dashed'>

            <div className="col-md-12 text-end py-1">
                <button className='btn btn-sm btn-success me-1' onClick={onSubmit} disabled={loading}>
                    {loading && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="sr-only">Loading...</span></div>}
                    Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} />
                </button>
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>

            </div>
            <div className="col-md-12 py-3">
                <h3 className='fnt-w9 text-center txt-primary'>{section?.meta?.title || defaultData?.title}</h3>
            </div>

            {
                Array.isArray(section?.meta?.boxes) ?

                    section?.meta?.boxes.map(el =>
                        <div className="col-md-4 p-2">
                            <div className="col-md-12">
                                <h3 className='fnt-w9 text-center txt-primary'>{el?.title}</h3>
                            </div>

                            <div className='col-md-12 text-center'>
                                <img style={{ maxWidth: '100%' }} src={el?.img || defaultImage} alt="" />
                            </div>
                            <div className='col-md-12 pt-3 text-center'>
                                {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                            </div>
                        </div>
                    )
                    :
                    defaultData?.boxes.map(el =>
                        <div className="col-md-4 p-2">
                            <div className="col-md-12">
                                <h3 className='fnt-w9 text-center txt-secondary'>{el?.title}</h3>
                            </div>

                            <div className='col-md-12 text-center'>
                                <img style={{ maxWidth: '100%' }} src={el?.img} alt="" />
                            </div>
                            <div className='col-md-12 pt-3 text-center'>
                                {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                            </div>
                        </div>
                    )
            }





        </div >
    );
};

export default Layout3ImageTextBox;