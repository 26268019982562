import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewFormation, updateFormation } from '../../slices/formation.slice';
import { upload } from '../../slices/page.slice';

import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FormationConfig from './FormationConfig';
import FormationResume from './FormationResume';
import FormationObjectif from './FormationObjectif';
import FormationInfo from './FormationInfo';

const FormationDetails = () => {

    const { loading, error } = useSelector(state => state.formation_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [formation, set_formation] = useState([])

    const load_data = () => {
        if (params?.id)
            dispatch(viewFormation(params?.id)).unwrap().then(res => {
                if (res?.done && res?.entity) {
                    set_formation(res?.entity)
                }
            })
    }

    const onUpdateFormation = (data) => {
        if (data) {
            if (data?.fiche_formation_file) {
                console.log(data?.fiche_formation_file)
                const formData = new FormData();
                formData.append('file', data?.fiche_formation_file);

                dispatch(upload(formData)).unwrap().then(res_upload => {
                    if (res_upload?.path) {
                        let tmp = { ...data, fiche_formation: res_upload?.path }
                        dispatch(updateFormation(tmp)).unwrap().then(res => {
                            if (res?.done) {
                                load_data()
                            }
                        })
                    }
                })
            } else
                dispatch(updateFormation(data)).unwrap().then(res => {
                    if (res?.done) {
                        load_data()
                    }
                })
        }
    }

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className='row m-0 p-1'>

            <div className='col-md-12 p-1 card my-1'>

                <div className="row m-0 dvdr-b-gray py-1">
                    <div className="col-md-1 text-center pt-1">
                        <b className="icon-item-round">
                            <FontAwesomeIcon icon={['fas', 'graduation-cap']} />
                        </b>
                    </div>
                    <div className="col-md-6 pt-1">
                        <h4 className='m-0'>{formation?.libelle}</h4>
                    </div>

                </div>

                <div className="row m-0 py-1">
                    <div className="col-md-1">
                        <b className='text-muted fnt-w4  fnt-sm'>Ref</b>
                        <p className='txt-dark mb-1 fnt-sm fnt-w7'>#{formation?.id}</p>
                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Etat</b>
                        {formation?.is_publish ? <Badge bg='success' className='fnt-sm fnt-w5'>Publié</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non-Publié</Badge>}
                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Diplome d'etat</b>
                        {formation?.is_diplome_etat ? <Badge bg='success' className='fnt-sm fnt-w5'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non</Badge>}
                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>France Competence</b>
                        {formation?.is_france_competence ? <Badge bg='success' className='fnt-sm fnt-w5'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non</Badge>}
                    </div>
                </div>

            </div>


            <div className="col-md-12 mt-2 px-0">
                <Tabs
                    defaultActiveKey="config"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="config" title={<><FontAwesomeIcon icon={['fas', 'cog']} /> Config</>}>
                        <FormationConfig onSave={onUpdateFormation} formation={formation} loading={loading} error={error} />
                    </Tab>
                    <Tab eventKey="resume" title={<><FontAwesomeIcon icon={['fas', 'file-alt']} /> Resumé</>}>
                        <FormationResume onSave={onUpdateFormation} formation={formation} loading={loading} error={error} />
                    </Tab>
                    <Tab eventKey="objectif" title={<><FontAwesomeIcon icon={['fas', 'bullseye']} /> Objectifs</>} >
                        <FormationObjectif onSave={onUpdateFormation} formation={formation} loading={loading} error={error} />
                    </Tab>
                    <Tab eventKey="info" title={<><FontAwesomeIcon icon={['fas', 'clipboard-list']} /> Informations</>} >
                        <FormationInfo onSave={onUpdateFormation} formation={formation} load_data={load_data} loading={loading} error={error} />
                    </Tab>
                </Tabs>
            </div>

        </div>
    );
};

export default FormationDetails;