import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listCandidature, updateCandidature, deleteCandidature } from '../../slices/candidature.slice';

import Badge from 'react-bootstrap/Badge';
import OrganismeBox from '../main/OrganismeBox';


const Candidatures = () => {


    const { loading, error } = useSelector(state => state.candidature_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [show_form, set_show_form] = useState(false)

    const { sel_organisme } = useSelector(state => state.organisme_store);
    useEffect(() => {
        load_data()
    }, [sel_organisme])

    const doSearch = value => {
    }

    const load_data = () => {
        if (sel_organisme)
            dispatch(listCandidature()).unwrap().then(res => {
                if (Array.isArray(res?.rows)) {
                    set_lst_data(res?.rows)
                }
            })
    }

    const onDeleteCand = (id) => {
        if (id)
            dispatch(deleteCandidature(id)).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                }
            })
    }

    const onUpdateCandStatus = (item, status) => {
        if (item)
            dispatch(updateCandidature({ ...item, is_traite: status })).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                }
            })
    }

    const onShowForm = () => {
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_show_form(false)
    }


    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>
            <OrganismeBox />
            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'briefcase']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Les Candidatures <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>

                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf/Nom  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <CandidatureList lst_order={lst_data} onDelete={onDeleteCand} onUpdateStatus={onUpdateCandStatus} />
            </div>


            {/* <PageForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onSave={onCreatePage} /> */}


        </div>
    );
};


const CandidatureList = ({ lst_order, onDelete, onUpdateStatus }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 card my-1'>

                        <div className="row m-0 pt-2 ">

                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Ref</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>#{el?.id}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Nom</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.nom}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Prénom</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.prenom}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>E-mail</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.email}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm'>Tél</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.tel}</p>
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Etat</b>
                                {el?.is_traite ? <Badge bg='success'>Traité</Badge> : <Badge bg='warning' className='text-dark'>En-attente</Badge>}
                            </div>
                            <div className="col-md-4 pt-2 dvdr-t-gray">
                                <b className='text-muted fnt-w4  fnt-sm'>Formation</b>
                                <Link to={`/app/formation/${el?.formation?.id}`} target="_blank" rel="noopener noreferrer">
                                    <p className='txt-secondary mb-1 fnt-sm fnt-w7'>{el?.formation?.libelle || '---'}</p>
                                </Link>
                            </div>

                            <div className="col-md-2 dvdr-t-gray">
                                <b className='text-muted fnt-w4  fnt-sm'>DDN</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.ddn || '---'}</p>
                            </div>
                            <div className="col-md-2 dvdr-t-gray">
                                <b className='text-muted fnt-w4  fnt-sm'>Adresse</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.adresse || '---'}</p>
                            </div>
                            <div className="col-md-2 dvdr-t-gray">
                                <b className='text-muted fnt-w4  fnt-sm'>Niveau Educ</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>{el?.niv_educ || '---'}</p>
                            </div>



                            <div className="col-md-2 pt-3 dvdr-t-gray">
                                <button className='btn btn-sm btn-danger me-1' onClick={e => onDelete(el?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                                {
                                    !el?.is_traite ?
                                        <button className='btn btn-sm btn-success' onClick={e => onUpdateStatus(el, true)}><FontAwesomeIcon className='me-3' icon={['fas', 'check-circle']} />Traité</button>
                                        :
                                        <button className='btn btn-sm btn-danger' onClick={e => onUpdateStatus(el, false)}><FontAwesomeIcon className='me-3' icon={['fas', 'cancel']} />Non-Traité</button>
                                }
                                {/* <button className='btn btn-sm btn-success w-100'><FontAwesomeIcon className='me-0' icon={['fas', 'long-arrow-alt-right']} /></button> */}
                            </div>

                        </div>
                    </div>
                )
            }


        </div>
    );

}
export default Candidatures;