import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listFormation } from '../../../slices/formation.slice';

import ic_diplome_etat from '../../../assets/img/ic_diplome_etat.png'
import ic_france_comp from '../../../assets/img/ic_france_comp.png'

import Badge from 'react-bootstrap/Badge'

const filter_type = [
    { label: 'Tous', value: null },
    { label: 'Initial', value: 'is_initial' },
    { label: 'Alternance', value: 'is_alternance' },
]

const filter_bac = [
    { label: 'Tous', value: null },
    { label: 'Bac', value: 'bac' },
    { label: 'Bac +2', value: 'bac+2' },
    { label: 'Bac +3', value: 'bac+3' },
    { label: 'Bac +4', value: 'bac+4' },
]
const FormationWidget = ({ section, onDelete, onEditOrder, index, onSave }) => {

    const { loading, error } = useSelector(state => state.formation_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [sel_filter_type, set_sel_filter_type] = useState(null)
    const [sel_filter_bac, set_sel_filter_bac] = useState(null)
    const [sel_filter_categ, set_sel_filter_categ] = useState(null)
    const [section_meta, set_section_meta] = useState(null)

    const doSearch = value => {
    }


    const onAddCateg = () => {
        if (sel_filter_categ && section_meta) {
            let c = []
            if (Array.isArray(section_meta?.categ_filter)) {
                c = [...section_meta?.categ_filter, sel_filter_categ]
            } else {
                c = [...c, sel_filter_categ]
            }
            onSave({ ...section, meta_data: { ...section_meta, categ_filter: c } })
            set_sel_filter_categ("")
        }
    }

    const onDelCateg = (data) => {
        if (data && section_meta) {
            console.log(data)
            let c = []
            if (Array.isArray(section_meta?.categ_filter)) {
                c = section_meta?.categ_filter.filter(el => el != data)
            }
            onSave({ ...section, meta_data: { ...section_meta, categ_filter: c } })
        }
    }


    const load_data = () => {
        let filter = {}
        if (sel_filter_type) {
            filter[sel_filter_type] = true
        }
        if (sel_filter_bac) {
            filter['niv_requis'] = sel_filter_bac
        }

        if (Array.isArray(section?.meta_data?.categ_filter)) {
            if (section?.meta_data?.categ_filter.length > 0)
                filter['categorie'] = section?.meta_data?.categ_filter
        }

        dispatch(listFormation(filter)).unwrap().then(res => {
            console.log(res?.rows)
            if (Array.isArray(res?.rows)) {
                set_lst_data(res?.rows)
            }
        })
    }

    useEffect(() => {
        load_data()
    }, [sel_filter_type, sel_filter_bac, section?.meta_data])

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])


    return (
        <div className='row m-0 my-3 brd-b-dashed'>
            <div className="col-md-12 text-end py-2">
                <b className='d-inline-block fnt-w5 px-2'><FontAwesomeIcon icon={['fas', 'filter']} /> | </b>
                {
                    Array.isArray(section_meta?.categ_filter) && section_meta?.categ_filter.map(el => <Badge style={{ cursor: 'pointer' }} bg='warning' className='me-1' onClick={e => onDelCateg(el)}>{el} <FontAwesomeIcon className='ms-2' icon={['fas', 'times']} /></Badge>)
                }
                <input type="text" value={sel_filter_categ} onChange={e => set_sel_filter_categ(e?.target?.value)} className={`form-control form-control-sm d-inline-block w-25 ms-2`} />
                <button className='btn btn-sm btn-success me-3' disabled={loading} onClick={e => onAddCateg()}><FontAwesomeIcon icon={['fas', 'plus']} /></button>
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

            </div>
            <div className="col-md-12">
                <h1 className='fnt-w9 txt-primary ps-2'>Découvrez nos formations</h1>
            </div>

            <div className="col-md-12 my-3">
                <div className="row m-0 p-3 text-center formation-filter-container">
                    <div className="col-auto pt-1">
                        <FontAwesomeIcon icon={['fas', 'filter']} />
                    </div>

                    <div className="col-md-auto dvdr-l-gray">
                        {
                            Array.isArray(filter_type) && filter_type.map(el => <button key={`btn-flt-${el?.value}`} className={`btn btn-sm btn-formation-filter me-2 ${sel_filter_type == el?.value && 'btn-formation-filter-active'}`} onClick={e => set_sel_filter_type(el?.value)}>{el?.label}</button>)
                        }
                    </div>
                    <div className="col-md-auto dvdr-l-gray">
                        {
                            Array.isArray(filter_bac) && filter_bac.map(el => <button key={`btn-flt-${el?.value}`} className={`btn btn-sm btn-formation-filter me-2 ${sel_filter_bac == el?.value && 'btn-formation-filter-active'}`} onClick={e => set_sel_filter_bac(el?.value)}>{el?.label}</button>)
                        }
                    </div>


                </div>
            </div>

            <div className="col-md-12">
                <div className="row m-0 ">
                    {
                        Array.isArray(lst_data) && lst_data.map(el =>
                            <FormationItem item={el} key={`itm-form-${el?.id}`} />
                        )
                    }
                </div>
            </div>

        </div>
    );
};

const FormationItem = ({ item }) => {
    return (
        <div className="col-md-3 p-2 formation-item">
            <Link to={`#`}>
                <div className="row m-0 formation-list-container px-2 py-4">
                    <h4 className='fnt-w9 txt-primary' style={{ height: 60, overflow: 'hidden' }}>{item?.libelle}</h4>
                    <div className="col-md-12 pb-1">
                        <b className='fnt-w9 text-dark'>{item?.duree || '-'}</b>
                    </div>
                    <div className="col-md-12 pb-1" style={{ height: 28, overflow: 'hidden' }}>
                        {item?.is_alternance && <Badge bg='warning txt-primary no-border-rad me-2 text-dark'>Alternance</Badge>}
                        {item?.is_initial && <Badge bg='warning txt-primary no-border-rad me-2 text-dark'>Initiale</Badge>}
                    </div>
                    <p style={{ height: 56, overflow: 'hidden' }} className='text-dark'>{item?.descriptif_court}</p>
                    <div className="col-md-12 text-center pb-2" style={{ height: 56 }}>
                        {item?.is_diplome_etat && <img className='me-2' src={ic_diplome_etat} height={46} alt="" />}
                        {item?.is_france_competence && <img src={ic_france_comp} height={46} alt="" />}
                    </div>

                    <button className='btn formation-btn-secondary py-2 w-100 mt-3'>VOIR LA FORMATION <FontAwesomeIcon icon={['fas', 'caret-right']} /></button>
                </div>
            </Link>
        </div>
    )
}
export default FormationWidget;