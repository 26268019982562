import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/page.service';



export const listPage = createAsyncThunk(
    "Page/adm-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.list(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createPage = createAsyncThunk(
    "Page/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.create(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updatePage = createAsyncThunk(
    "Page/adm-update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.update(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const duplicatePage = createAsyncThunk(
    "Page/adm-duplicate",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.duplicate(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const upload = createAsyncThunk(
    "Page/adm-upload",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.upload(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const deletePage = createAsyncThunk(
    "Page/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.delete(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const viewPage = createAsyncThunk(
    "Page/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.view(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const searchPage = createAsyncThunk(
    "Page/adm-search",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.search(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createSection = createAsyncThunk(
    "Section/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createSection(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updateSection = createAsyncThunk(
    "Section/adm-update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.updateSection(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const deleteSection = createAsyncThunk(
    "Section/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteSection(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)





export const createMenu = createAsyncThunk(
    "Menu/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createMenu(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updateMenu = createAsyncThunk(
    "Menu/adm-update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.updateMenu(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const deleteMenu = createAsyncThunk(
    "Menu/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteMenu(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listMenu = createAsyncThunk(
    "Menu/adm-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listMenu(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)





const initialState = {
    loading: false,
    error: '',
}

const PageSlice = createSlice({
    name: "Page",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },

    },
    extraReducers: builder => {
        builder
            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })



    }
})

const { reducer } = PageSlice;
export const { clear } = PageSlice.actions;
export default reducer;