export const admin_routes = [
    { path: '', label: 'Accueil', icon: 'home', exact: true },
    { path: 'organisme', label: 'Organisme', icon: 'university', exact: false },
    { path: 'candidature', label: 'Candidatures', icon: 'briefcase', exact: false },
    { path: 'formation', label: 'Formations', icon: 'graduation-cap', exact: false },
    { path: 'pages', label: 'Pages', icon: 'columns', exact: false },
    { path: 'contact-msg', label: 'Messages', icon: 'comment-dots', exact: false },
    { path: 'users', label: 'Users', icon: 'users', exact: false },

]
