import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import FormationInfoEditor from './FormationInfoEditor';
import FormationProgramme from './FormationProgrammeEdit';
import FormationLieeEdit from './FormationLieeEdit';

const FormationInfo = ({ formation, onSave, load_data, loading, error }) => {
    return (
        <div className='row m-0 formation-container py-3'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="programme">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column formation-v-nav pt-0">
                            <Nav.Item><Nav.Link eventKey="programme">Programme</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="pre_requis">Pré-requis</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="modalite_inscription">Modalités d'inscription</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="admission">Admission</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="publics_concernes">Publics concernés</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="sortie_formation">A la sortie de formation</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="intervenant">Intervenants</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="accessibilite">Accessibilité</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="debouches">Débouchés</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="poursuite_etudes">Poursuite d'études</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="contacts">Contacts utiles</Nav.Link></Nav.Item>

                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>

                            <Tab.Pane eventKey="programme">
                                <FormationProgramme formation={formation} onSave={onSave} loading={loading} load_data={load_data} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="pre_requis">
                                <FormationInfoEditor target_fields={[{ title: 'Pré-requis', field: 'descriptif_pre_requis' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="modalite_inscription">
                                <FormationInfoEditor target_fields={[{ title: 'Alternance', field: 'descriptif_alternance' }, { title: 'Initiale', field: 'descriptif_initial' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="admission">
                                <FormationInfoEditor target_fields={[{ title: 'Votre admission', field: 'admission' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="publics_concernes">
                                <FormationInfoEditor target_fields={[{ title: 'Publics concernés', field: 'publics_concernes' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sortie_formation">
                                <FormationInfoEditor target_fields={[{ title: 'A la sortie de la formation', field: 'sortie_formation' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="intervenant">
                                <FormationInfoEditor target_fields={[{ title: 'Intervenant', field: 'intervenant' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="accessibilite">
                                <FormationInfoEditor target_fields={[{ title: 'Accessibilités', field: 'accessibilite' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="debouches">
                                <FormationLieeEdit formation={formation} type={'debouche'} load_data={load_data} error={error} loading={loading} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="poursuite_etudes">
                                <FormationLieeEdit formation={formation} type={'poursuite'} load_data={load_data} error={error} loading={loading} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="contacts">
                                <FormationInfoEditor target_fields={[{ title: 'Contacts utiles', field: 'contact' }]} formation={formation} onSave={onSave} loading={loading} />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
    );
};

export default FormationInfo;