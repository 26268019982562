import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import htmlEditButton from "quill-html-edit-button";
import FieldEditor from './FieldEditor';



const FormationInfoEditor = ({ title, formation, target_fields, onSave, loading, error }) => {

    // const Font = Quill.import('formats/font');
    // Font.whitelist = ['poppin'];
    // Quill.register(Font, true);

    Quill.register({
        "modules/htmlEditButton": htmlEditButton
    })

    const [value, setValue] = useState('');
    const [is_edit, set_is_edit] = useState(false);
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ["small", false, "large", "huge"] }]
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': ['poppin'] }],
            ['clean']
        ],
        htmlEditButton: {}
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'size', 'code', 'code-block', 'color', 'background',
    ]

    // const onEdit = () => { set_is_edit(true) }

    // const onSubmit = () => {
    //     if (formation && target_field && value) {
    //         onSave({ id: formation?.id, [target_field]: value })
    //         set_is_edit(false)
    //     }
    // }

    // useEffect(() => {
    //     if (formation) {
    //         setValue(formation[target_field])
    //     }
    // }, [formation, target_field])


    return (
        <div className='row m-0 formation-container p-3'>
            {
                Array.isArray(target_fields) && target_fields.map(el =>
                    <div className="col-md-12 formation-info-box">
                        <h4 className='formation-info-box-title'>{el?.title}</h4>
                        <FieldEditor target_field={el?.field} formation={formation} onSave={onSave} loading={loading} />
                    </div>
                )
            }

        </div>
    );
};

export default FormationInfoEditor;