import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class AuthService {

    login(data) {
        return axios.post(`${api_url}/public/login`, data);
    }

    logout() {
        storage.logout();
    }

    checkUser() {
        let token = storage.getToken();
        return axios.get(`${api_url}/auth/check`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    viewProfile() {
        let token = storage.getToken();
        return axios.get(`${api_url}/auth/profile`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    updateProfile(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/auth/profile`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

}

export default new AuthService()