import React, { useEffect, useState } from 'react';
import { listOrganisme, setSelOrganisme, loadSelOrganisme } from '../../slices/organisme.slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrganismeBox = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, sel_organisme } = useSelector(state => state.organisme_store);
    const [lst_organisme, set_lst_organisme] = useState([]);

    const load_organisme = () => {

        dispatch(listOrganisme()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_organisme(res?.rows)
            }
        })
    }

    const onSelectOrganisme = (value) => {
        console.log("on select organisme")
        console.log(value)
        console.log("-------------------")
        if (value && Array.isArray(lst_organisme)) {
            let tmp = lst_organisme.find(el => el?.id == value)
            if (tmp) {
                window.ORGANISME_TOKEN = tmp?.public_token;
                dispatch(setSelOrganisme(tmp))
            }
        }
    }

    // useEffect(() => {
    //     console.log('sel_organisme changed ::')
    //     console.log(sel_organisme)
    //     if (sel_organisme)
    //         window.ORGANISME_TOKEN = sel_organisme?.public_token;
    // }, [sel_organisme])

    useEffect(() => {
        dispatch(loadSelOrganisme())
        load_organisme()
    }, [])

    useEffect(() => {
        dispatch(loadSelOrganisme())
        if (Array.isArray(lst_organisme) && lst_organisme.length > 0 && !window.ORGANISME_TOKEN && !sel_organisme) {
            console.log("on load organisme no sel found !")
            console.log(sel_organisme)
            console.log("-------------------")
            window.ORGANISME_TOKEN = lst_organisme[0]?.public_token;
            dispatch(setSelOrganisme(lst_organisme[0]))
        }
    }, [lst_organisme])

    return (
        <div className='row m-0 mt-0 home-banner no-border-rad'>
            <div className="col-md-8"></div>
            <div className="col-md-4 text-end dvdr-l-gray py-2">
                <label className="fnt-w7 txt-secondary d-inline-block me-2"><FontAwesomeIcon icon={['fas', 'university']} /></label>
                <Form.Select className='d-inline-block w-75' size='sm' value={sel_organisme?.id} onChange={e => onSelectOrganisme(e?.target?.value)}>
                    <option value="" disabled selected>Choisissez une option</option>
                    {
                        Array.isArray(lst_organisme) && lst_organisme.map(el => <option value={el?.id}>{el?.nom}</option>)
                    }
                </Form.Select>
            </div>
        </div>
    );
};

export default OrganismeBox;