import React, { useEffect } from 'react';
import bg_objectif from '../../assets/img/bg_objectif.svg'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import FieldEditor from './FieldEditor';

const FormationObjectif = ({ formation, onSave, loading, error }) => {

    const validationSchema = Yup.object().shape({
        objectif: Yup.string().nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (formation) {
            onSave({ ...data, id: formation?.id })
            reset()
        }
    }

    useEffect(() => {
        if (formation) {
            setValue('objectif', formation?.objectif)
        } else {
            reset()
        }
    }, [formation])



    return (
        <div className='row m-0 formation-container py-3'>
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="row m-0 p-0">
                <div className="col-md-12 text-center px-4 py-1" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-success w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                    }
                </div>

                <div className="col-md-12 ">
                    <div className="row m-0"></div>
                    <div className="row m-0 px-5 formation-objectif-container">

                        <div className="col-md-4 text-center">
                            <img src={bg_objectif} width="100%" alt="" />
                        </div>
                        <div className="col-md-8">
                            {/* <label className='fnt-sm content-editor-label'>Objectif</label> */}
                            {/* <textarea {...register("objectif")} rows="20" class="form-control content-editor-input text-light "></textarea> */}
                            <FieldEditor target_field={'objectif'} formation={formation} onSave={onSave} loading={loading} />
                        </div>

                    </div>
                    <div className="row m-0"></div>
                </div>
            </form>

        </div>
    );
};

export default FormationObjectif;