import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginForm = ({ loading, error, onLogin }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('this field is required !'),
        //  .email('Email not valid !'),
        password: Yup.string()
            .min(6, 'password not valid !')
            .required('this field is required !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        onLogin(data)
    }

    return (
        <div className="col-md-12   mt-5 ps-5 pe-5 pt-5" >
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="ps-2">
                <div className="form-group">
                    <label className="fnt-w4 txt-gray"><FontAwesomeIcon icon={['fas', 'envelope']} /> E-mail</label>
                    <input placeholder="e-mail ..."  {...register("email")} className={`form-control ${errors.email ? 'is-invalid pt-2 pb-2' : 'form-control pt-2 pb-2'}`} />
                    <p className="text-danger">{errors.email?.message}</p>
                </div>

                <div className="form-group">
                    <label className="fnt-w4 txt-gray"><FontAwesomeIcon icon={['fas', 'unlock-alt']} /> Mot de passe</label>
                    <input type="password" placeholder="password ..."  {...register("password")} className={`form-control ${errors.password ? 'is-invalid pt-2 pb-2' : 'form-control pt-2 pb-2'}`} />
                    <p className="text-danger">{errors.password?.message}</p>
                </div>


                <div className="col-md-12 text-center" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >se connecter <FontAwesomeIcon className='ms-3' icon={['fas', 'long-arrow-alt-right']} /></button>
                    }
                </div>

            </form>

        </div>
    );
};

export default LoginForm;