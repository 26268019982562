import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class FormationService {

    list(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/list`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    view(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/view/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }


    duplicate(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/duplicate/${data?.idFormation}/${data?.idOrg}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    delete(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    search(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/search/${data}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    searchPublish(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/public/formation/search/${data}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    listPublish() {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/public/formation/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }




    createProgramme(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/create-programme`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    updateProgramme(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/update-programme`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    deleteProgramme(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/delete-programme/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }





    createLiee(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/create-liee`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    updateLiee(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/formation/update-liee`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    deleteLiee(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/formation/delete-liee/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

}

export default new FormationService()