import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from "react-redux";
import { searchPublishFormation, createLiee, deleteLiee, updateLiee } from '../../slices/formation.slice';
import LieeForm from './LieeForm';

const types = ['debouche', 'poursuite']
const FormationLieeEdit = ({ formation, type, load_data, loading, error }) => {

    const dispatch = useDispatch();
    const [show_form, set_show_form] = useState(false)
    const [sel_item, set_sel_item] = useState(null)

    const onSaveLiee = (data) => {
        if (sel_item)
            dispatch(updateLiee(data)).unwrap().then(res => {
                if (res?.done) {
                    onCloseForm()
                    load_data()
                }
            })
        else
            dispatch(createLiee({ ...data, formationId: formation?.id, type: type })).unwrap().then(res => {
                if (res?.done) {
                    onCloseForm()
                    load_data()
                }
            })
    }

    const onShowForm = () => {
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_sel_item(null)
        set_show_form(false)
    }

    const onEditLiee = (item) => {
        set_sel_item(item)
        set_show_form(true)
    }

    const onDeleteLiee = (item) => {
        dispatch(deleteLiee(item?.id)).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }

    return (
        <div className='row m-0 formation-container py-3'>
            <div className="col-md-12 formation-info-box mb-5">
                <div className="row m-0">
                    <h4 className='formation-info-box-title'>{type == 'debouche' ? 'Débouchés de la formation' : 'Poursuite d\'études de la formation'}</h4>
                    <div className="col-md-12 py-1 text-end">
                        <button className='btn btn-success' onClick={onShowForm}><FontAwesomeIcon icon={['fas', 'plus']} /> Ajouter une formation Liée</button>
                    </div>
                </div>

                <div className="row m-0 py-2">
                    {
                        Array.isArray(formation?.formation_liees) && formation?.formation_liees.filter(el => el?.type == type).map(el =>
                            <div className='col-md-4'>
                                <div className="row m-0 formation-list-container px-2 py-4">
                                    <h4 className='fnt-w9 txt-primary' style={{ minHeight: 60, overflow: 'hidden' }}>{el?.libelle}</h4>

                                    <div className="col-md-6">
                                        {
                                            el?.duree && <b>{el?.duree}</b>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <a href={el?.lien_formation} target='_blank' rel='noreferrer' className='btn formation-btn-secondary py-1 w-100 fnt-w5'><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></a>
                                    </div>
                                    <div className="col-md-12 text-end pt-2">
                                        <button className="btn btn-sm btn-default" onClick={e => onEditLiee(el)}><FontAwesomeIcon icon={['fas', 'edit']} /></button>
                                        <button className="btn btn-sm btn-default" onClick={e => onDeleteLiee(el)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <LieeForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onSave={onSaveLiee} sel_item={sel_item} />
            </div>



        </div>
    );
};

export default FormationLieeEdit;