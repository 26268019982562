import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPage, createPage, duplicatePage } from '../../slices/page.slice';

import Badge from 'react-bootstrap/Badge';
import PageForm from './PageForm';
import OrganismeBox from '../main/OrganismeBox';
import Menus from './menu/Menus';
import DuplicateBox from './DuplicateBox';


const Pages = () => {


    const { loading, error } = useSelector(state => state.page_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [lst_data, set_lst_data] = useState([])
    const [show_form, set_show_form] = useState(false)
    const [show_menus, set_show_menus] = useState(false)
    const [show_dup, set_show_dup] = useState(false)
    const [sel_item, set_sel_item] = useState(null)

    const { sel_organisme } = useSelector(state => state.organisme_store);
    useEffect(() => {
        load_data()
    }, [sel_organisme])

    const doSearch = value => {
    }

    const load_data = () => {
        if (sel_organisme)
            dispatch(listPage()).unwrap().then(res => {
                if (Array.isArray(res?.rows)) {
                    set_lst_data(res?.rows)
                }
            })
    }

    const onCreatePage = (data) => {
        dispatch(createPage(data)).unwrap().then(res => {
            if (res?.done) {
                onCloseForm()
                load_data()
            }
        })
    }


    const onShowForm = () => {
        set_show_form(true)
    }

    const onCloseForm = () => {
        set_show_form(false)
    }


    const onDuplicatePage = (idPage, idOrg) => {
        dispatch(duplicatePage({ idPage, idOrg })).unwrap().then(res => {
            if (res?.done) {
                onCloseDuplicate()
                load_data()
            }
        })
    }

    const onShowDuplicate = (value) => {
        set_sel_item(value)
        set_show_dup(true)
    }
    const onCloseDuplicate = (value) => {
        set_sel_item(null)
        set_show_dup(false)
    }


    useEffect(() => {
        load_data()
    }, [])



    return (
        <div className='col-md-12 p-2'>
            <OrganismeBox />
            <div className="row m-0 home-banner ">
                <div className="col-md-12  p-2">
                    <div className="row m-0">
                        <div className="col-md-12 align-items-end dvdr-b-gray pb-2">
                            <div className="d-inline-block w-auto pt-1">
                                <b className="icon-item-round">
                                    <FontAwesomeIcon icon={['fas', 'columns']} />
                                </b>
                            </div>
                            <h5 className="d-inline-block txt-secondary ps-2 pt-2 mb-0 ">Les Pages <span class="badge rounded-pill bg-success fnt-sm pt-1">{Array.isArray(lst_data) ? lst_data.length : '--'}</span></h5>

                            <button className='btn btn-warning text-dark float-end' onClick={e => set_show_menus(true)} ><FontAwesomeIcon icon={['fas', 'ellipsis-v']} /> Menu</button>
                            <button className='btn btn-success float-end me-1' onClick={onShowForm} ><FontAwesomeIcon icon={['fas', 'plus']} />Nouvelle Page</button>


                        </div>

                        <div className="col-md-6  pt-2 pb-1 offset-md-3">
                            <label className="m-0 small-label form-label fnt-w5 fnt-sm ps-3">Recherche</label>
                            <Form.Group as={Col} controlId="formGridState" className="no-marg col-md-12">
                                <Form.Control type="search" className="form-control inpt_search brd-rad-4 shadow-none " placeholder="Recherche par Réf/Nom  ..." onChange={e => doSearch(e.target.value)} />
                                <button className="btn btn_search brd-rad-4"><FontAwesomeIcon icon={['fas', 'search']} /></button>
                            </Form.Group>
                        </div>


                    </div>
                </div>

            </div>

            <div className="row m-0 mt-2">
                {error &&

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                    </div>
                }
            </div>


            <div className="row m-0 mt-2">
                <PageList lst_order={lst_data} onDuplicatePage={onShowDuplicate} loading={loading} />
            </div>


            <PageForm show={show_form} onClose={onCloseForm} loading={loading} error={error} onSave={onCreatePage} />
            <Menus show={show_menus} onClose={e => set_show_menus(false)} />
            <DuplicateBox show={show_dup} onClose={onCloseDuplicate} idPage={sel_item?.id} onDuplicate={onDuplicatePage} />



        </div>
    );
};


const PageList = ({ lst_order, onDuplicatePage, loading }) => {

    return (
        <div className="row m-0">
            {
                Array.isArray(lst_order) && lst_order.map(el =>
                    <div className='col-md-12 p-0 card my-1'>

                        <div className="row m-0 pt-2 ">

                            <div className="col-md-1">
                                <b className='text-muted fnt-w4  fnt-sm'>Ref</b>
                                <p className='txt-dark mb-1 fnt-sm fnt-w7'>#{el?.id}</p>
                            </div>
                            <div className="col-md-4">
                                <b className='text-muted fnt-w4  fnt-sm'>Titre</b>
                                <p className='txt-secondary mb-1 fnt-sm fnt-w7'>{el?.titre}</p>
                            </div>

                            <div className="col-md-1">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Etat</b>
                                {el?.is_publish ? <Badge bg='success' className='fnt-sm fnt-w5'>Publié</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non-Publié</Badge>}
                            </div>
                            <div className="col-md-1">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Acceuil</b>
                                {el?.is_home ? <Badge bg='success' className='fnt-sm fnt-w5'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non</Badge>}
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Inclus-Menu</b>
                                {el?.is_menu ? <Badge bg='success' className='fnt-sm fnt-w5'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5'>Non</Badge>}
                            </div>
                            <div className="col-md-2">
                                <b className='text-muted fnt-w4  fnt-sm d-block'>Menu</b>
                                {<Badge bg='dark' className='fnt-sm fnt-w5'>{el?.target_menu || '---'}</Badge>}
                            </div>

                            <div className="col-md-1 text-end pt-1">
                                <button className='btn btn-sm btn-warning me-1' disabled={loading} onClick={e => onDuplicatePage(el)}><FontAwesomeIcon className='me-0' icon={['fas', 'clone']} /></button>

                                <Link to={`${el?.id}`}>
                                    <button className='btn btn-sm btn-success'><FontAwesomeIcon className='me-0' icon={['fas', 'long-arrow-alt-right']} /></button>
                                </Link>
                            </div>

                        </div>
                    </div>
                )
            }


        </div>
    );

}
export default Pages;