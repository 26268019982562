import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewPage, updatePage, deletePage, createSection, updateSection, upload, deleteSection } from '../../slices/page.slice';
import LayoutImageTextHoriz from './templates/LyImageTextHoriz';
import LayoutImageTextVert from './templates/LyImageTextVert';
import Layout3ImageTextBox from './templates/Ly3ImageTextBox';
import Badge from 'react-bootstrap/Badge';
import SectionForm from './SectionForm';
import PageForm from './PageForm';
import ContactWidget from './widgets/ContactWidget';
import FormationWidget from './widgets/FormationWidget';
import FaqWidget from './widgets/FaqWidget';
import LyHeroBshape from './templates/LyHeroBshape';
import LayoutOnlyText from './templates/LyOnlyText';
import LyTable from './templates/LyTable';
import LyVideo from './templates/LyVideo';

const PageDetails = () => {

    const { loading, error } = useSelector(state => state.page_store);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [page, set_page] = useState(null)
    const [show_sect_form, set_show_sect_form] = useState(false)
    const [show_page_form, set_show_page_form] = useState(false)

    const load_data = () => {
        if (params?.id)
            dispatch(viewPage(params?.id)).unwrap().then(res => {
                if (res?.done && res?.entity) {
                    set_page(res?.entity)
                }
            })
    }

    const onDeleteSection = (id) => {
        if (id)
            dispatch(deleteSection(id)).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                }
            })
    }

    const onEditOrder = (item, index, value) => {
        if (Array.isArray(page?.page_sections)) {
            if ((value == -1 && index > 0 && index < page?.page_sections?.length) || (value == +1 && (+index + +1) < page?.page_sections?.length)) {
                let itemI = { ...item, order: (+item?.order + +value) }
                let itemN = { ...page?.page_sections[(+index + +value)], order: (+page?.page_sections[(+index + +value)]?.order + -value) }

                dispatch(updateSection(itemI)).unwrap().then(res => {
                    if (res?.done) {
                        dispatch(updateSection(itemN)).unwrap().then(res => {
                            if (res?.done) {
                                load_data()
                            }
                        })
                    }
                })

            }
        }

    }

    useEffect(() => {
        load_data()
    }, [])

    const renderSection = (item, index) => {
        switch (item?.layout) {

            case "LayoutImageTextHorizLeft": return <LayoutImageTextHoriz section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutImageTextHorizRight": return <LayoutImageTextHoriz section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutImageTextVert": return <LayoutImageTextVert section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutOnlyText": return <LayoutOnlyText section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutTable": return <LyTable section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutVideo": return <LyVideo section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "Layout3ImageTextBox": return <Layout3ImageTextBox section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "LayoutHeroBShape": return <LyHeroBshape section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onUpload={onUploadFile} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "WidgetContact": return <ContactWidget section={item} key={`sect-data-${item?.id}`} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "WidgetFormation": return <FormationWidget section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;
            case "WidgetFaq": return <FaqWidget section={item} key={`sect-data-${item?.id}`} onSave={onUpdateSection} onDelete={onDeleteSection} onEditOrder={onEditOrder} index={index} loading={loading} />;

            default: return <h1>No layout match</h1>
        }
    }


    const onCreateSection = (data) => {
        if (data && page) {
            data.pageId = page?.id
            data.order = Array.isArray(page?.page_sections) ? page?.page_sections.length : 0
            dispatch(createSection(data)).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                    set_show_sect_form(false)
                }
            })
        }
    }

    const onUpdateSection = (data) => {
        if (data) {
            dispatch(updateSection(data)).unwrap().then(res => {
                if (res?.done) {
                    load_data()
                }
            })

        }
    }

    const onUploadFile = (file, section, field) => {
        if (file && section && field) {
            const formData = new FormData();
            formData.append('file', file);

            dispatch(upload(formData)).unwrap().then(res_upload => {
                if (res_upload?.path) {
                    let tmp = { ...section, meta_data: { ...section?.meta_data, [field]: res_upload?.path } }
                    onUpdateSection(tmp)
                }
            })

        }
    }


    const onUpdatePage = (data) => {
        if (data) {
            dispatch(updatePage(data)).unwrap().then(res => {
                if (res?.done) {
                    set_show_page_form(false)
                    load_data()
                }
            })
        }
    }

    const onDeletePage = () => {
        if (page) {
            dispatch(deletePage(page?.id)).unwrap().then(res => {
                if (res?.done) {
                    navigate('/app/pages')
                }
            })
        }
    }

    return (
        <div className='row m-0 p-1'>

            <div className='col-md-12 p-1 card my-1'>

                <div className="row m-0 dvdr-b-gray py-1">
                    <div className="col-md-1 text-center pt-1">
                        <b className="icon-item-round">
                            <FontAwesomeIcon icon={['fas', 'columns']} />
                        </b>
                    </div>
                    <div className="col-md-6 pt-1">
                        <h4 className='m-0'>{page?.titre}</h4>
                    </div>
                    <div className="col-md-5 text-end">
                        <button className='btn btn-sm btn-warning me-2' onClick={e => set_show_page_form(true)}><FontAwesomeIcon icon={['fas', 'cog']} /></button>
                        <button className='btn btn-sm btn-danger' onClick={onDeletePage}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                    </div>

                </div>

                <div className="row m-0 py-2">
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm'>Ref | <span className='fnt-w7 txt-secondary'>#{page?.id}</span></b>
                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Etat | {page?.is_publish ? <Badge bg='success' className='fnt-sm fnt-w5 no-border-rad'>Publié</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5 no-border-rad'>Non-Publié</Badge>}</b>

                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Page d'Acceuil | {page?.is_home ? <Badge bg='success' className='fnt-sm fnt-w5 no-border-rad'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5 no-border-rad'>Non</Badge>}</b>

                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Inclus-Menu | {page?.is_menu ? <Badge bg='success' className='fnt-sm fnt-w5 no-border-rad'>Oui</Badge> : <Badge bg='danger' className='fnt-sm fnt-w5 no-border-rad'>Non</Badge>}</b>

                    </div>
                    <div className="col-md-2">
                        <b className='text-muted fnt-w4  fnt-sm d-block'>Menu | {<Badge bg='dark' className='fnt-sm fnt-w5 no-border-rad'>{page?.target_menu || '---'}</Badge>}</b>

                    </div>
                </div>

            </div>

            <div className="col-md-12 p-0 card my-3 no-border-rad">
                <div className="row m-0 py-3">
                    <h1 className='fnt-w9 txt-primary'>{page?.titre}</h1>
                </div>

                {
                    Array.isArray(page?.page_sections) && page?.page_sections.map((el, index) => renderSection(el, index))
                }

                <div className="col-md-12 text-center py-3">
                    <button className='btn btn-dark no-border-rad' onClick={e => set_show_sect_form(true)}><FontAwesomeIcon className='me-2' icon={['fas', 'plus']} />Ajouter Une Section</button>
                </div>
            </div>

            <SectionForm show={show_sect_form} onCreate={onCreateSection} onClose={e => set_show_sect_form(false)} loading={loading} error={error} />
            <PageForm show={show_page_form} onClose={e => set_show_page_form(false)} loading={loading} error={error} onSave={onUpdatePage} sel_item={page} />

        </div>
    );
};

export default PageDetails;