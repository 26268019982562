import React, { useEffect, useState } from 'react';
import { listOrganisme } from '../../slices/organisme.slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DuplicateBox = ({ show, onClose, idFormation, onDuplicate }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.formation_store);
    const [lst_organisme, set_lst_organisme] = useState([]);
    const [sel_org, set_sel_org] = useState(null);

    const load_organisme = () => {
        dispatch(listOrganisme()).unwrap().then(res => {
            if (Array.isArray(res?.rows)) {
                set_lst_organisme(res?.rows)
            }
        })
    }

    const onSubmit = () => {
        if (sel_org && idFormation)
            onDuplicate(idFormation, sel_org)
    }

    useEffect(() => {
        load_organisme()
    }, [])


    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Duplication Formation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 text-center  py-2">
                    <label className="fnt-w7 txt-secondary d-inline-block me-2"><FontAwesomeIcon icon={['fas', 'university']} /></label>
                    <Form.Select className='d-inline-block w-75' size='sm' value={sel_org} onChange={e => set_sel_org(e?.target?.value)}>
                        <option value="" disabled selected>Choisissez une option</option>
                        {
                            Array.isArray(lst_organisme) && lst_organisme.map(el => <option value={el?.id}>{el?.nom}</option>)
                        }
                    </Form.Select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={loading}>
                    Annuler
                </Button>
                <Button variant="success" onClick={onSubmit} disabled={loading}>
                    Confirmer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DuplicateBox;