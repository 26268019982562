import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import parse from 'html-react-parser';
import Offcanvas from 'react-bootstrap/Offcanvas';

const defaultData = {
    style_bg: '',
    style_title: '',
    questions: [
        { id: 0, title: 'Question ?', content: '<p>Réponse ....</p>' }
    ]
}

const FaqWidget = ({ section, onSave, onDelete, loading, onEditOrder, index }) => {

    const [section_meta, set_section_meta] = useState(null)
    const [show_form, set_show_form] = useState(false)
    const [sel_item, set_sel_item] = useState(null)

    const onShowForm = value => {
        set_sel_item(value)
        set_show_form(true)
    }
    const onCloseForm = value => {
        set_sel_item(null)
        set_show_form(false)
    }

    const onSubmit = (data) => {
        if (section && section_meta && Array.isArray(section_meta?.questions)) {
            let tmp = []
            if (sel_item) {
                tmp = section_meta?.questions.map(el => el?.id == sel_item?.id ? { ...data, id: sel_item?.id } : el)
            } else {
                tmp = [...section_meta?.questions, { ...data, id: section_meta?.questions?.length }]
            }
            onSave({ ...section, meta_data: { ...section_meta, questions: tmp } })
            onCloseForm()
        }
    }
    const onDeleteQuestion = (id) => {
        if (section && section_meta && Array.isArray(section_meta?.questions)) {
            let tmp = []
            if (id) {
                tmp = section_meta?.questions.filter(el => el?.id != id).map((el, index) => ({ ...el, id: index }))
                onSave({ ...section, meta_data: { ...section_meta, questions: tmp } })
            }

        }
    }

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])
    return (
        <div className='row m-0 my-5 brd-b-dashed py-3'>
            <div className="col-md-12 text-end py-2">
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

            </div>
            <h1 className='text-center txt-primary'>FAQ</h1>
            <div className="col-md-4 text-center">
                <img src="/img/faq_bg.svg" width='80%' alt="" />
            </div>
            <div className="col-md-8 mb-5">
                <Accordion defaultActiveKey={0}>
                    {
                        Array.isArray(section_meta?.questions) && section_meta?.questions?.map(el =>
                            <Accordion.Item eventKey={el?.id}>
                                <Accordion.Header className='fnt-w9'><span className='fnt-w9'>{el?.title}</span></Accordion.Header>
                                <Accordion.Body>
                                    <div className="col-md-12 py-1 text-end">
                                        <button className='btn btn-sm btn-warning me-2' onClick={e => onShowForm(el)}><FontAwesomeIcon icon={['fas', 'edit']} /></button>
                                        <button className='btn btn-sm btn-danger' onClick={e => onDeleteQuestion(el?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>

                                    </div>
                                    {parse(String(el?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                </Accordion>
            </div>

            <div className="col-md-12 text-end">
                <button className='btn btn-sm btn-success' onClick={e => onShowForm(null)}><FontAwesomeIcon icon={['fas', 'plus']} /> Ajouter une question</button>
            </div>

            <FaqForm sel_item={sel_item} onSave={onSubmit} show={show_form} onClose={onCloseForm} loading={loading} />

        </div>
    );
};

const FaqForm = ({ sel_item, onSave, show, onClose, loading, ...props }) => {
    const [title, set_title] = useState(sel_item?.title || '')
    const [content, set_content] = useState(sel_item?.content || '')

    const onSubmit = () => {
        if (title && content) {
            onSave({ title, content })
            set_title('')
            set_content('')
        }
    }

    useEffect(() => {
        if (sel_item) {
            set_title(sel_item?.title)
            set_content(sel_item?.content)
        }
    }, [sel_item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    {`${sel_item ? 'Edit Question' : 'Nouvelle Question'}`}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>


                <div className='row m-0'>
                    <div className="col-md-12 form-group py-2">
                        <label className='fnt-w7'>Question</label>
                        <input className='form-control form-control-sm ' type="text" value={title} onChange={e => set_title(e?.target?.value)} />
                    </div>
                    <div className="col-md-12 form-group py-2">
                        <label className='fnt-w7'>Réponse</label>
                        <textarea className='form-control form-control-sm ' rows="6" type="text" value={content} onChange={e => set_content(e?.target?.value)} />
                    </div>

                    <div className="col-md-12 text-end py-3">
                        <button className='btn btn-sm btn-success' disabled={loading} onClick={onSubmit}><FontAwesomeIcon icon={['fas', 'check-circle']} /> Sauvegarder</button>
                    </div>

                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )

}

export default FaqWidget; 