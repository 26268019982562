import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import defaultImage from '../../../assets/img/ic_photo.png'
import LayoutFieldEditor from './FieldEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_file_url } from '../../../config/Params.constant';

const direction_var = { left: 'left', right: 'right' }
const defaultData = {
    style_bg: 'ly-bg-white',
    title: 'Section Title',
    style_title: '',
    direction: 'left',
    img: defaultImage,
    style_img: 'ly-img-compress',
    content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>',
    btn_text: 'LINK',
    btn_link: '#',
    style_btn: 'ly-btn-primary',
    style_col: {
        col_img: 'col-md-6',
        col_content: 'col-md-6',
    }
}

const style_bg_var = ['ly-bg-primary', 'ly-bg-secondary', 'ly-bg-primary-grad', 'ly-bg-secondary-grad', 'ly-bg-white', 'ly-bg-dark', 'ly-bg-gray']
const style_btn_var = ['ly-btn-primary', 'ly-btn-secondary', 'ly-btn-primary ly-bg-primary-grad', 'ly-btn-secondary ly-bg-secondary-grad', 'ly-btn-dark']
const LayoutImageTextHoriz = ({ section, onSave, onUpload, onDelete, loading, onEditOrder, index }) => {

    const [section_meta, set_section_meta] = useState(null)

    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);


    const onEditMeta = (field, data) => {
        if (field && section_meta)
            set_section_meta({ ...section_meta, [field]: data })
    }

    const onSubmit = () => {
        if (section && section_meta) {
            onSave({ ...section, meta_data: section_meta })
        }
    }

    const onSubmitUpload = (field) => {
        if (sel_img && section && field) {
            onUpload(sel_img, { ...section, meta_data: section_meta }, field)
        }
    }

    const onImgChange = (e) => {
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));
    }

    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
    }, [section])

    return (
        <div className={`row m-0 p-0 brd-b-dashed position-relative ${section_meta?.style_bg}`} >

            <div className="col-md-12  py-1 position-absolute top-0 z-ind99">
                <div className="row m-0">
                    <div className="col-md-6 p-2">
                        {
                            style_bg_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_bg', el)}></button>)
                        }
                    </div>
                    <div className="col-md-6 text-end">
                        <button className='btn btn-sm btn-success me-1' onClick={onSubmit} disabled={loading}>
                            {loading && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="sr-only">Loading...</span></div>}
                            Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} />
                        </button>
                        <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                        <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                        <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

                    </div>
                </div>

            </div>
            {/* <div className="col-md-12">
                {/* <h2 className='fnt-w9 text-center txt-primary'>{section_meta?.title || defaultData.title}</h2> 
                <textarea rows="auto" value={section_meta?.title} onChange={e => onEditMeta('title', e?.target?.value)} class="form-control content-editor-input fnt-w9 text-center txt-primary section-title"></textarea>
            </div> */}
            {
                section_meta?.direction == direction_var.left ?
                    <>
                        <div className={`${section_meta?.style_col?.col_img} p-0 text-center position-relative`}>
                            <img style={{ maxWidth: '100%' }} className={`${section_meta?.style_img}`} src={sel_img_tmp || (section_meta?.img ? `${api_file_url}${section_meta?.img}` : defaultData.img)} alt="" />
                            <div className="col-md-6 p-2 position-absolute bottom-0 start-0 z-ind99">
                                <div className="col-md-12 pb-1 text-start ">
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_img', 'ly-img-expand')}><FontAwesomeIcon icon={['fas', 'expand']} /></button>
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_img', 'ly-img-compress')}><FontAwesomeIcon icon={['fas', 'compress']} /></button>
                                </div>
                                <input type="file" accept="image/*,.pdf" onChange={onImgChange} />
                                {sel_img_tmp && sel_img && <button className='btn btn-sm btn-success' onClick={e => onSubmitUpload('img')}><FontAwesomeIcon icon={['fas', 'upload']} /></button>}
                            </div>
                        </div>
                        <div className={`${section_meta?.style_col?.col_content} pt-5 pb-2 position-relative`}>
                            <div className="col-md-4 p-2 text-start position-absolute top-0 start-0 z-ind99 ">
                                <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_col', { col_img: 'col-md-6', col_content: 'col-md-6' })}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                                <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_col', { col_img: 'col-md-4', col_content: 'col-md-8' })}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} /></button>
                            </div>
                            {/* {section_meta?.content ? parse(String(section_meta?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : parse(String(defaultData?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')} */}
                            <LayoutFieldEditor field={'content'} value={section_meta?.content} onChange={onEditMeta} />


                            <div className="col-md-12 mt-3">
                                <div className="col-md-12 pb-1 ">
                                    {
                                        style_btn_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_btn', el)}></button>)
                                    }
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_btn', 'ly-btn-hide')}><FontAwesomeIcon icon={['fas', 'eye-slash']} /></button>

                                </div>

                                {/* <a target='_blank' href={`${section_meta?.btn_link ? section_meta?.btn_link : '#'}`} className={`btn px-5 ${section_meta?.style_btn}`}>{section_meta?.btn_text}</a> */}
                                <input value={section_meta?.btn_text} onChange={e => onEditMeta('btn_text', e?.target?.value)} class={`form-control content-editor-input fnt-w9 text-center w-auto px-0 ${section_meta?.style_btn}`} />
                                {
                                    section_meta?.style_btn != 'ly-btn-hide' &&

                                    <div class="input-group col-md-6 w-50">
                                        <label class="input-group-text no-border-rad"><FontAwesomeIcon icon={['fas', 'link']} /></label>
                                        <input class="form-control form-control-sm no-border-rad" type="url" placeholder='http://...' value={section_meta?.btn_link} onChange={e => onEditMeta('btn_link', e?.target?.value)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </>
                    :
                    <>

                        <div className={`${section_meta?.style_col?.col_content} pt-5 pb-2 position-relative`}>
                            <div className="col-md-4 p-2 text-start position-absolute top-0 start-50 z-ind99 ">
                                <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_col', { col_img: 'col-md-4', col_content: 'col-md-8' })}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                                <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_col', { col_img: 'col-md-6', col_content: 'col-md-6' })}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} /></button>
                            </div>
                            {/* {section_meta?.content ? parse(String(section_meta?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '') : parse(String(defaultData?.content).replace(/(?:\r\n|\r|\n)/g, '<br />') || '')} */}
                            <LayoutFieldEditor field={'content'} value={section_meta?.content} onChange={onEditMeta} />


                            <div className="col-md-12 mt-3">
                                <div className="col-md-12 pb-1 ">
                                    {
                                        style_btn_var.map(el => <button className={`btn shadow ${el} me-2 p-3`} onClick={e => onEditMeta('style_btn', el)}></button>)
                                    }
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_btn', 'ly-btn-hide')}><FontAwesomeIcon icon={['fas', 'eye-slash']} /></button>
                                </div>

                                {/* <a target='_blank' href={`${section_meta?.btn_link ? section_meta?.btn_link : '#'}`} className={`btn px-5 ${section_meta?.style_btn}`}>{section_meta?.btn_text}</a> */}
                                <input value={section_meta?.btn_text} onChange={e => onEditMeta('btn_text', e?.target?.value)} class={`form-control content-editor-input fnt-w9 text-center w-auto px-0 ${section_meta?.style_btn}`} />
                                {
                                    section_meta?.style_btn != 'ly-btn-hide' &&

                                    <div class="input-group col-md-6 w-50">
                                        <label class="input-group-text no-border-rad"><FontAwesomeIcon icon={['fas', 'link']} /></label>
                                        <input class="form-control form-control-sm no-border-rad" type="url" placeholder='http://...' value={section_meta?.btn_link} onChange={e => onEditMeta('btn_link', e?.target?.value)} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`${section_meta?.style_col?.col_img} p-0 text-center position-relative`}>
                            <img style={{ maxWidth: '100%' }} className={`${section_meta?.style_img}`} src={sel_img_tmp || (section_meta?.img ? `${api_file_url}${section_meta?.img}` : defaultData.img)} alt="" />
                            <div className="col-md-6 p-2 position-absolute bottom-0 start-0 z-ind99">
                                <div className="col-md-12 pb-1 text-start ">
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_img', 'ly-img-expand')}><FontAwesomeIcon icon={['fas', 'expand']} /></button>
                                    <button className={`btn shadow btn-light me-2 p-1 px-2 no-border-rad`} onClick={e => onEditMeta('style_img', 'ly-img-compress')}><FontAwesomeIcon icon={['fas', 'compress']} /></button>
                                </div>
                                <input type="file" accept="image/*,.pdf" onChange={onImgChange} />
                                {sel_img_tmp && sel_img && <button className='btn btn-sm btn-success' onClick={e => onSubmitUpload('img')}><FontAwesomeIcon icon={['fas', 'upload']} /></button>}
                            </div>
                        </div>

                    </>
            }

        </div>
    );
};

export default LayoutImageTextHoriz;