import axios from 'axios';
import { api_url } from '../config/Params.constant';
import storage from './Storage';

class PageService {

    list(data) {
        let token = storage.getToken();
        //let organisme_token = window?.ORGANISME_TOKEN;
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/list`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    create(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    view(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/view/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    upload(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/adm/rsc/upload`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    update(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    duplicate(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/duplicate/${data?.idPage}/${data?.idOrg}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    delete(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    search(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/search/${data}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }



    createSection(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/create-section`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    updateSection(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/update-section`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    deleteSection(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/delete-section/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }



    createMenu(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/create-menu`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    updateMenu(data) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.post(`${api_url}/adm/page/update-menu`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

    deleteMenu(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/delete-menu/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }


    listMenu(id) {
        let token = storage.getToken();
        let organisme_token = storage.getOrganisme()?.public_token;
        return axios.get(`${api_url}/adm/page/list-menu`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "organisme-token": organisme_token
            }
        });
    }

}

export default new PageService()