import React, { useEffect } from 'react';
import ic_diplome_etat from '../../assets/img/ic_diplome_etat.png'
import ic_france_comp from '../../assets/img/ic_france_comp.png'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';

const FormationResume = ({ formation, onSave, loading, error }) => {

    const validationSchema = Yup.object().shape({
        libelle: Yup.string().required('Ce champ est obligatoire !'),
        descriptif_court: Yup.string().nullable(),
        descriptif_long: Yup.string().nullable(),
        campus: Yup.string().nullable(),
        duree: Yup.string().nullable(),
        certification: Yup.string().nullable(),
        prochaine_session: Yup.string().nullable(),
        pre_requis: Yup.string().nullable(),

        is_diplome_etat: Yup.boolean().required('Ce champ est obligatoire !'),
        is_france_competence: Yup.boolean().required('Ce champ est obligatoire !'),


    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (formation) {
            onSave({ ...data, id: formation?.id })
            reset()
        }
    }

    useEffect(() => {
        if (formation) {
            setValue('libelle', formation?.libelle)
            setValue('descriptif_court', formation?.descriptif_court)
            setValue('descriptif_long', formation?.descriptif_long)
            setValue('campus', formation?.campus)
            setValue('duree', formation?.duree)
            setValue('certification', formation?.certification)
            setValue('prochaine_session', formation?.prochaine_session)
            setValue('pre_requis', formation?.pre_requis)

            setValue('is_diplome_etat', formation?.is_diplome_etat)
            setValue('is_france_competence', formation?.is_france_competence)
        } else {
            reset()
        }
    }, [formation])



    return (
        <div className='row m-0 formation-container py-3'>
            {error &&

                <div className="alert alert-danger" role="alert">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                </div>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="row m-0 p-0">
                <div className="col-md-12 text-center px-4 py-1" >
                    {loading ?
                        <div className="col-md-12 p-4 text-ctr">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <button type="submit" className="btn btn-success w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                    }
                </div>

                <div className="col-md-12">
                    <div className="row m-0">


                        <div className="col-md-6">
                            <label className='fnt-sm content-editor-label'>Libelle</label>
                            <textarea {...register("libelle")} rows="auto" class="form-control content-editor-input formation-title"></textarea>
                            {/* <h1 className='formation-title'>{formation?.libelle}</h1> */}

                            <label className='fnt-sm content-editor-label'>Descriptif Court</label>
                            <textarea {...register("descriptif_court")} rows="auto" class="form-control content-editor-input formation-sub-title"></textarea>
                            {/* <h3 className='formation-sub-title'>{formation?.descriptif_court}</h3> */}

                            <label className='fnt-sm content-editor-label'>Descriptif Long</label>
                            <textarea {...register("descriptif_long")} rows="auto" class="form-control content-editor-input formation-description-dark"></textarea>
                            {/* <p className='formation-description-dark'>{formation?.descriptif_long}</p> */}

                            <div className="row m-0 disabled-area">
                                <div className="col-md-6 ps-0">
                                    <button className='btn formation-btn-primary py-3 w-100'>VOIR LE PROGRAMME</button>
                                </div>
                                <div className="col-md-6 pe-0">
                                    <button className='btn formation-btn-secondary py-3 w-100'>TÉLÉCHARGER LA FICHE</button>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-6">
                            <div className="row m-0 formation-resume-box-r p-3">
                                <div className="col-md-6 ">
                                    <label className='fnt-sm content-editor-label'>Diplome d'etat</label>
                                    <Form.Check
                                        className='w-50 mb-2 content-editor-input'
                                        type="switch"
                                        label=""
                                        {...register("is_diplome_etat")}
                                    />
                                    <img src={ic_diplome_etat} alt="" />
                                </div>

                                <div className="col-md-6 ">
                                    <label className='fnt-sm content-editor-label'>France compétence</label>
                                    <Form.Check
                                        className='w-50 mb-2 content-editor-input'
                                        type="switch"
                                        label=""
                                        {...register("is_france_competence")}
                                    />
                                    <img src={ic_france_comp} alt="" />
                                </div>

                                <div className="col-md-12 py-3"></div>

                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>CAMPUS</h4>
                                    <label className='fnt-sm content-editor-label'>Campus</label>
                                    <textarea {...register("campus")} rows="1" class="form-control content-editor-input "></textarea>

                                    {/* <p>{formation?.campus}</p> */}
                                </div>

                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>DURÉE</h4>

                                    <label className='fnt-sm content-editor-label'>Duree</label>
                                    <textarea {...register("duree")} rows="1" class="form-control content-editor-input "></textarea>

                                    <p>{formation?.duree}</p>
                                </div>


                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>CERTIFICATION</h4>

                                    <label className='fnt-sm content-editor-label'>Certification</label>
                                    <textarea {...register("certification")} rows="1" class="form-control content-editor-input "></textarea>

                                    <p>{formation?.certification}</p>
                                </div>


                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>PRÉ-REQUIS</h4>

                                    <label className='fnt-sm content-editor-label'>Pré_Requis</label>
                                    <textarea {...register("pre_requis")} rows="2" class="form-control content-editor-input "></textarea>

                                    {/* <p>{formation?.pre_requis}</p> */}
                                </div>


                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>PROCHAINE SESSION</h4>

                                    <label className='fnt-sm content-editor-label'>Prochaine-Session</label>
                                    <textarea {...register("prochaine_session")} rows="1" class="form-control content-editor-input "></textarea>

                                    {/* <p>{formation?.prochaine_session}</p> */}
                                </div>

                                <div className="col-md-12">
                                    <h4 className='formation-mini-title'>TARIF</h4>
                                    <p>Nos tarifs et mode de financement sont consultable sur <a href='#' className='text-danger'>cette page.</a></p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default FormationResume;