import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

const LieeForm = ({ loading, error, onSave, show, onClose, sel_item, ...props }) => {

    const validationSchema = Yup.object().shape({
        libelle: Yup.string().required('Ce champ est obligatoire !'),
        duree: Yup.string().nullable(),
        lien_formation: Yup.string().nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {
        //console.log(data)
        if (sel_item)
            onSave({ ...sel_item, libelle: data?.libelle, duree: data?.duree, lien_formation: data?.lien_formation })
        else
            onSave(data)
        reset()
    }

    useEffect(() => {
        if (sel_item) {
            setValue('libelle', sel_item?.libelle)
            setValue('duree', sel_item?.duree)
            setValue('lien_formation', sel_item?.lien_formation)
        } else {
            reset()
        }
    }, [sel_item])

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    Nouvelle Matière
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className="row m-0">

                        <div className="col-md-12 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Libelle</label>
                            <input type="text" {...register("libelle")} className={`form-control form-control-sm `} />
                            <p className="text-danger">{errors.libelle?.message}</p>
                        </div>

                        <div className="col-md-4 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Durée</label>
                            <input type="text" {...register("duree")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.duree?.message}</p>
                        </div>

                        <div className="col-md-4 form-group col-12">
                            <label className="fnt-w7 fnt-sm">Lien</label>
                            <input type="text" {...register("lien_formation")} className={`form-control form-control-sm`} />
                            <p className="text-danger">{errors.lien_formation?.message}</p>
                        </div>



                        <div className="col-md-12 text-center" >
                            {loading ?
                                <div className="col-md-12 p-4 text-ctr">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-dark  mt-4 w-auto float-end px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                            }
                        </div>

                    </form>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default LieeForm;