import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/auth.service';
import Storage from '../services/Storage';


export const login = createAsyncThunk(
    "auth/login",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.login(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const checkAuth = createAsyncThunk(
    "auth/checkAuth",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.checkUser(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const initialState = {
    loading: false,
    error: '',
    loggedUser: null,
}

const AuthSlice = createSlice({
    name: "Auth",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },
        loadLoggedUser: (state) => {
            state.loggedUser = Storage.getData()
        },
        logout: (state) => {
            Storage.logout()
            Object.assign(state, initialState)
        },

    },
    extraReducers: builder => {
        builder

            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = '';
                state.loggedUser = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.error = '';
                if (action.payload?.token)
                    Storage.setToken(action.payload?.token)
                if (action.payload?.user) {
                    state.loggedUser = action.payload?.user;
                    Storage.setData(action.payload?.user)
                }
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.loggedUser = null;
            })



            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                if (action.payload == 'Request failed with status code 401')
                    state.error = 'Votre session à été expirée , reconnectez vous !';
            })



    }
})

const { reducer } = AuthSlice;
export const { clear, loadLoggedUser, logout } = AuthSlice.actions;
export default reducer;