import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth.slice';
import formationReducer from '../slices/formation.slice';
import pageReducer from '../slices/page.slice';
import organismeReducer from '../slices/organisme.slice';
import candidatureReducer from '../slices/candidature.slice';
import messageReducer from '../slices/message.slice';
import userReducer from '../slices/user.slice';

export const store = configureStore({
  reducer: {
    auth_store: authReducer,
    formation_store: formationReducer,
    page_store: pageReducer,
    organisme_store: organismeReducer,
    candidature_store: candidatureReducer,
    message_store: messageReducer,
    user_store: userReducer,
  },
});
