import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import htmlEditButton from "quill-html-edit-button";




const LayoutFieldEditor = ({ field, value, onChange }) => {

    // const Font = Quill.import('formats/font');
    // Font.whitelist = ['poppin'];
    // Quill.register(Font, true);

    // Quill.register({
    //     "modules/htmlEditButton": htmlEditButton
    // })
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ["small", false, "large", "huge"] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            [{ 'color': ['#ffffff', '#000000', '#262b40', '#be231f', '#fab029', '#212529', '#198754', '#332984', '#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9', '#9b59b6', '#8e44ad', '#34495e', '#2c3e50', '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#ecf0f1', '#bdc3c7', '#95a5a6', '#7f8c8d',] }, { 'background': ['#ffffff', '#000000', '#262b40', '#be231f', '#fab029', '#212529', '#198754', '#332984', '#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9', '#9b59b6', '#8e44ad', '#34495e', '#2c3e50', '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#ecf0f1', '#bdc3c7', '#95a5a6', '#7f8c8d',] }],          // dropdown with defaults from theme
            // [{ 'font': ['poppin'] }],
            ['clean']
        ],
        // htmlEditButton: {},

    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'size', 'code', 'code-block', 'color', 'background', 'align'
    ]


    return (

        <div className="col-md-12 px-0 brd-dashed">
            <ReactQuill className='' theme="snow" modules={modules} formats={formats} value={value} onChange={v => onChange(field, v)} />
        </div>
    );
};

export default LayoutFieldEditor;