import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';

const defaultData = {
    columns: [{ key: `col_0`, title: 'column 0' }, { key: `col_1`, title: 'column 1' }],
    rows: [{ id: 0, col_0: 'column:0 row:0', col_1: 'column:1 row:0' }, { id: 1, col_0: 'column:0 row:1', col_1: 'column:1 row:1' },]
}
const LyTable = ({ section, onSave, onDelete, loading, onEditOrder, index }) => {

    const [section_meta, set_section_meta] = useState(null)
    const [columns, set_columns] = useState([])
    const [rows, set_rows] = useState([])
    const [new_cl_name, set_new_cl_name] = useState('')


    const onSubmit = () => {
        if (section && section_meta && Array.isArray(rows) && Array.isArray(columns)) {
            onSave({ ...section, meta_data: { columns: columns, rows: rows } })
        }
    }


    useEffect(() => {
        console.log({ ...section?.meta_data })
        set_section_meta({ ...section?.meta_data })
        if (Array.isArray(section?.meta_data?.columns) && Array.isArray(section?.meta_data?.rows)) {
            set_columns(section?.meta_data?.columns)
            set_rows(section?.meta_data?.rows)
        }
    }, [section])


    const addRow = () => {
        if (Array.isArray(columns) && Array.isArray(rows)) {
            let id = rows.length
            let new_record = { id: rows.length }
            columns.forEach((el, index) => {
                if (el?.key) {
                    new_record[el?.key] = `column:${index} row:${id}`
                }

            });
            let tmp = [...rows, new_record]
            set_rows(tmp)
        }
    }

    const addColumn = () => {
        if (Array.isArray(columns) && Array.isArray(rows) && new_cl_name) {
            let tmp_c = [...columns, { key: `col_${columns.length}`, title: new_cl_name }]
            let tmp_r = rows.map((el, index) => ({ ...el, [`col_${columns.length}`]: `column:${columns.length} row:${index}` }))
            console.log(tmp_c)
            set_columns(tmp_c)
            set_rows(tmp_r)
            set_new_cl_name('')
        }
    }

    const onEditRow = (id, field, value) => {
        if (Array.isArray(rows)) {
            let tmp_r = rows.map((el) => el?.id == id ? ({ ...el, [field]: value }) : el)
            set_rows(tmp_r)
        }
    }

    const onDeleteRow = (id) => {
        if (Array.isArray(rows)) {
            let tmp_r = rows.filter(el => el?.id != id).map((el, index) => ({ ...el, id: index }))
            set_rows(tmp_r)
        }
    }

    const onEditColumn = (key, value) => {
        if (Array.isArray(columns)) {
            let tmp_c = columns.map((el) => el?.key == key ? ({ ...el, title: value }) : el)
            set_columns(tmp_c)
        }
    }

    const onDeleteColumn = (key) => {
        if (Array.isArray(columns) && Array.isArray(rows)) {
            let tmp_c = columns.filter(el => el?.key != key)
            let tmp_r = []
            rows.forEach(el => {
                let t = { id: el?.id }
                tmp_c.forEach((cl, index) => {
                    t[`col_${index}`] = el[cl?.key]
                });
                tmp_r.push(t)
            });

            tmp_c = tmp_c.map((el, index) => ({ ...el, key: `col_${index}` }))
            set_columns(tmp_c)
            set_rows(tmp_r)

            console.log(tmp_c)
            console.log(tmp_r)
        }
    }

    return (
        <div className='row m-0 p-3'>

            <div className="col-md-12 text-end">
                <button className='btn btn-sm btn-success me-1' onClick={onSubmit} disabled={loading}>
                    {loading && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="sr-only">Loading...</span></div>}
                    Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} />
                </button>
                <button className='btn btn-sm btn-danger' disabled={loading} onClick={e => onDelete(section?.id)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>
                <button className="btn btn-sm btn-light mx-1" onClick={e => onEditOrder(section, index, +1)}><FontAwesomeIcon icon={['fas', 'sort-down']} /></button>
                <button className="btn btn-sm btn-light" onClick={e => onEditOrder(section, index, -1)}><FontAwesomeIcon icon={['fas', 'sort-up']} /></button>

            </div>


            <div className="col-md-8 py-2 ps-0">
                <input type="text" placeholder='Titre colonne' className='' value={new_cl_name} onChange={e => set_new_cl_name(e?.target?.value)} />
                <button className='btn btn-sm btn-success ms-2' onClick={addColumn}><FontAwesomeIcon icon={['fas', 'plus']} /> colonne</button>
            </div>
            <div className="col-md-4 text-end py-2">
                <button className='btn btn-sm btn-success' onClick={addRow}><FontAwesomeIcon icon={['fas', 'plus']} /> ligne</button>
            </div>

            {Array.isArray(columns) && Array.isArray(rows) &&
                <Table striped bordered hover>
                    <thead>
                        <tr className='text-center'>
                            {
                                columns.map(el => <THeadItem key={`th-${el?.key}`} item={el} onChange={onEditColumn} onDelete={onDeleteColumn} />)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(el =>
                                <ArrayItem key={`row-${el?.id}`} item={el} columns={columns} onChange={onEditRow} onDelete={onDeleteRow} />
                            )
                        }

                    </tbody>
                </Table>
            }

        </div>
    );
};


const ArrayItem = ({ item, columns, onChange, onDelete }) => {

    return (
        <tr>
            {columns.map(c =>
                <td key={`itm-${item?.id}-${c?.key}`}>
                    <textarea value={item[c?.key]} onChange={e => onChange(item?.id, c?.key, e?.target?.value)} class={`form-control content-editor-input d-inline-block fnt-w9 text-start w-100 `} />
                </td>
            )}
            <td className='text-center' onClick={e => onDelete(item?.id)}><button className='btn btn-sm btn-light text-danger'><FontAwesomeIcon icon={['fas', 'trash']} /></button></td>
        </tr>
    )
}


const THeadItem = ({ item, onChange, onDelete }) => {

    return (
        <th>
            <input value={item?.title} onChange={e => onChange(item?.key, e?.target?.value)} class={`form-control content-editor-input d-inline-block fnt-w9 text-center w-auto `} />
            <button className='btn btn-sm btn-light text-danger' onClick={e => onDelete(item?.key)}><FontAwesomeIcon icon={['fas', 'trash']} /></button>

        </th>
    )
}

export default LyTable;