import React, { useEffect } from 'react';
import login_bg from '../../assets/img/login_bg.jpg';
import logo from '../../assets/img/logo.png';
import { useNavigate } from "react-router-dom";
import LoginForm from '../../ui/form/login.form';
import { clear, login as doLogin } from '../../slices/auth.slice';
import { useDispatch, useSelector } from "react-redux";


const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);
    const onSubmit = data => {
        dispatch(doLogin(data))
    }

    useEffect(() => {
        if (loggedUser) {
            navigate('/app')
        }
    }, [loggedUser])


    return (
        <div className="row m-0 login-container bg-wt">

            <div className="col-md-6 h-100 ">
                <div className="col-md-12 text-center mt-5 mb-5">
                    <img src={logo} alt="" style={{ width: 180 }} />
                </div>
                <LoginForm loading={loading} error={error} onLogin={onSubmit} />
            </div>

            <div className="col-md-6 h-100 position-relative" style={{ minHeight: 720, overflowX: 'hidden', overflowY: 'hidden' }} >
                <img src={login_bg} className="bg_login" alt="" />
            </div>

        </div>
    );
};

export default Login;