import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import LayoutImageTextHoriz from '../../assets/img/ic_layout_img_txt_horiz.png';
import LayoutImageTextVert from '../../assets/img/ic_layout_img_txt_vert.png';
import LayoutOnlyText from '../../assets/img/ic_layout_text.png';
import LayoutTable from '../../assets/img/ic_layout_table.png';
import LayoutVideo from '../../assets/img/ic_layout_video.png';
import Layout3ImageTextBox from '../../assets/img/ic_layout_3ImgText.png';
import IcWidgetFormation from '../../assets/img/ic_widget_formation.png';
import IcWidgetFaq from '../../assets/img/ic_widget_faq.png';
import IcWidgetContact from '../../assets/img/ic_widget_contact.png';
import IcHeroBShape from '../../assets/img/ic_layout_hero1.png';
import ContactWidget from './widgets/ContactWidget';
import FormationWidget from './widgets/FormationWidget';
import FaqWidget from './widgets/FaqWidget';

const layouts = [
    {
        name: 'LayoutImageTextHorizLeft',
        title: 'Image Left Text Right',
        component: <img src={LayoutImageTextHoriz} alt='' width='100%' style={{ transform: 'scaleX(-1)' }} />,
        defaultMeta: {
            style_bg: 'ly-bg-white',
            title: 'Section Title',
            style_title: '',
            direction: 'left',
            img: null,
            style_img: 'ly-img-compress',
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>',
            btn_text: 'LINK',
            btn_link: '#',
            style_btn: 'ly-btn-primary',
            style_col: {
                col_img: 'col-md-6',
                col_content: 'col-md-6',
            }
        }
    },
    {
        name: 'LayoutImageTextHorizRight',
        title: 'Image Right Text Left',
        component: <img src={LayoutImageTextHoriz} alt='' width='100%' />,
        defaultMeta: {
            style_bg: 'ly-bg-white',
            title: 'Section Title',
            style_title: '',
            direction: 'right',
            img: null,
            style_img: 'ly-img-compress',
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>',
            btn_text: 'LINK',
            btn_link: '#',
            style_btn: 'ly-btn-primary',
            style_col: {
                col_img: 'col-md-6',
                col_content: 'col-md-6',
            }
        }
    },
    {
        name: 'LayoutImageTextVert',
        title: 'Image Text Vertical',
        component: <img src={LayoutImageTextVert} alt='' width='100%' />,
        defaultMeta: {
            title: 'Section Title',
            img: null,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        }
    },
    {
        name: 'LayoutOnlyText',
        title: 'Paragraphe',
        component: <img src={LayoutOnlyText} alt='' width='100%' />,
        defaultMeta: {
            title: 'Section Title',
            img: null,
            content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
        }
    },
    {
        name: 'LayoutTable',
        title: 'Table',
        component: <img src={LayoutTable} alt='' width='100%' />,
        defaultMeta: {
            columns: [{ key: `col_0`, title: 'column 0' }, { key: `col_1`, title: 'column 1' }],
            rows: [{ id: 0, col_0: 'column:0 row:0', col_1: 'column:1 row:0' }, { id: 1, col_0: 'column:0 row:1', col_1: 'column:1 row:1' },]
        }
    },

    {
        name: 'LayoutVideo',
        title: 'Video Youtube',
        component: <img src={LayoutVideo} alt='' width='100%' />,
        defaultMeta: {
            videoId: '',
            style_bg: 'ly-bg-white',
            content: '<p><h1>TITRE</h1><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'

        }
    },

    {
        name: 'LayoutHeroBShape',
        title: 'Hero Section',
        component: <img src={IcHeroBShape} alt='' width='100%' />,
        defaultMeta: {
            title: '<h1><strong class="ql-size-huge" style="color: rgb(250, 176, 41);">TITRE, </strong> </h1> <h2> <span class="ql-size-huge" style="color: rgb(255, 255, 255);">SLOGANT OU SOUS-TITRE </span> </h2>',
            btn_text: 'LINK',
            btn_link: '#',
            img: null,
            style_bg: 'ly-bg-primary',
            style_btn: 'ly-btn-secondary',
            style_img: '',
        }
    },
    // {
    //     name: 'Layout3ImageTextBox',
    //     title: '3 Inline Boxes',
    //     component: <img src={Layout3ImageTextBox} alt='' width='100%' />,
    //     defaultMeta: {
    //         title: 'Section Title',
    //         boxes: [
    //             {
    //                 title: 'Box Title',
    //                 img: null,
    //                 content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
    //             },
    //             {
    //                 title: 'Box Title',
    //                 img: null,
    //                 content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
    //             },
    //             {
    //                 title: 'Box Title',
    //                 img: null,
    //                 content: '<p><b>Lorem ipsum dolor</b> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>'
    //             },
    //         ]
    //     }
    // },
    {
        name: 'WidgetContact',
        title: 'Formulaire Contact',
        component: <img src={IcWidgetContact} alt='' width='100%' />,
        defaultMeta: {
            //...params
        }
    },
    {
        name: 'WidgetFormation',
        title: 'Formation List',
        component: <img src={IcWidgetFormation} alt='' width='100%' />,
        defaultMeta: {
            //...params
            categ_filter: []
        }
    },
    {
        name: 'WidgetFaq',
        title: 'FAQ',
        component: <img src={IcWidgetFaq} alt='' width='100%' />,
        defaultMeta: {
            style_bg: '',
            style_title: '',
            questions: [
                { id: 0, title: 'Question ?', content: '<p>Réponse ....</p>' }
            ]
        }
    },

]

const SectionForm = ({ loading, error, onCreate, show, onClose, ...props }) => {

    const [sel_ly, set_sel_ly] = useState(null)
    const onSubmit = () => {
        if (sel_ly && Array.isArray(layouts)) {
            let data = layouts.find(el => el?.name == sel_ly)
            onCreate({ layout: sel_ly, meta_data: data?.defaultMeta })
        }
    }

    return (

        <Offcanvas show={show} onHide={onClose} {...props} placement={'end'} className="w-75">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-dark'>
                    <b className="icon-item-round me-3">
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </b>
                    Nouvelle Section
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <div className="col-md-12" >
                    {error &&

                        <div className="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> <span className="ps-3">{error}</span>
                        </div>
                    }

                    <div className="row m-0 p-2">
                        <p className='text-center '>Choisissez une layouts</p>
                        {
                            Array.isArray(layouts) && layouts.map(el =>
                                <div className='col-md-4  p-2' onClick={e => set_sel_ly(el?.name)}>
                                    <div className="col-md-12 text-center card">
                                        <Form.Check
                                            type='checkbox'
                                            label=""
                                            className='ms-auto me-auto'
                                            checked={el?.name == sel_ly}
                                        />

                                        {el?.component}

                                        <b className='d-block py-2'>{el?.title}</b>
                                    </div>
                                </div>
                            )
                        }
                    </div>


                    <div className="col-md-12 text-center" >
                        {loading ?
                            <div className="col-md-12 p-4 text-ctr">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <button type="button" onClick={onSubmit} className="btn btn-dark w-auto px-3" >Sauvegarder <FontAwesomeIcon className='ms-3' icon={['fas', 'check-circle']} /></button>
                        }
                    </div>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default SectionForm;