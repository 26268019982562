import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction, isFullfieldAction } from './slice.helper';
import Api from '../services/organisme.service';
import Storage from "../services/Storage";



export const listOrganisme = createAsyncThunk(
    "Organisme/adm-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.list(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createOrganisme = createAsyncThunk(
    "Organisme/adm-create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.create(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updateOrganisme = createAsyncThunk(
    "Organisme/adm-update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.update(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const deleteOrganisme = createAsyncThunk(
    "Organisme/adm-delete",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.delete(data);
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)






const initialState = {
    loading: false,
    error: '',
    sel_organisme: null
}

const OrganismeSlice = createSlice({
    name: "Organisme",
    initialState: initialState,
    reducers: {
        clear: (state) => {
            Object.assign(state, initialState)
        },
        setSelOrganisme: (state, action) => {
            Storage.setOrganisme(action?.payload)
            state.sel_organisme = action?.payload;
        },
        loadSelOrganisme: (state) => {
            state.sel_organisme = Storage.getOrganisme()
        },

    },
    extraReducers: builder => {
        builder
            .addMatcher(isFullfieldAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
            })
            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })



    }
})

const { reducer } = OrganismeSlice;
export const { clear, setSelOrganisme, loadSelOrganisme } = OrganismeSlice.actions;
export default reducer;