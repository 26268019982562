import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOverview } from '../../slices/user.slice';

const Home = () => {

    const { loading, error } = useSelector(state => state.user_store);
    const history = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [overview_data, set_overview_data] = useState(null)

    const load_data = () => {
        dispatch(getOverview()).unwrap().then(res => {
            console.log(res)
            set_overview_data(res)
        })
    }

    useEffect(() => {
        load_data()
    }, [])


    return (
        <div className='row m-0 p-5'>
            <div className="col-md-12 px-5">
                <div className="row m-0">
                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'university']} /></h1>
                            <p className='text-center'>ORGANISME</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_org}</h2>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'graduation-cap']} /></h1>
                            <p className='text-center'>FORMATION</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_form}</h2>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'briefcase']} /></h1>
                            <p className='text-center'>CANDIDATURE</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_cand}</h2>
                        </div>
                    </div>

                </div>


                <div className="row m-0 mt-3">
                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'columns']} /></h1>
                            <p className='text-center'>PAGES</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_page}</h2>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'comment-dots']} /></h1>
                            <p className='text-center'>MESSAGE</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_msg}</h2>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="row m-0 card shadow px-3 py-5 text-center">
                            <h1 className='fnt-w9 txt-primary mb-0'><FontAwesomeIcon icon={['fas', 'user']} /></h1>
                            <p className='text-center'>USER</p>
                            <h2 className='fnt-w9 txt-secondary dvdr-t-gray'>{overview_data?.nbr_user}</h2>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default Home;