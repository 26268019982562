import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { admin_routes } from '../../utils/userRoutes.helper';
import { clear, logout, loadLoggedUser } from '../../slices/auth.slice';
import { loadSelOrganisme } from '../../slices/organisme.slice';
import AsideMenu from '../../ui/menu/AsideMenu';
import Home from './Home';
import Formations from '../formation/Formations';
import FormationDetails from '../formation/FormationDetails';
import Pages from '../page/Pages';
import PageDetails from '../page/PageDetails';
import Organismes from '../organisme/Organismes';
import OrganismeBox from './OrganismeBox';
import Candidatures from '../candidature/Candidatures';
import ContactMessages from '../message/ContactMessages';
import Users from '../user/Users';

const Main = () => {

    const [isOpen, setIsopen] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, loggedUser } = useSelector(state => state.auth_store);
    const { sel_organisme } = useSelector(state => state.organisme_store);

    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(3);




    const dologout = () => {
        dispatch(logout())
        navigate("/login");
    }

    useEffect(() => {
        dispatch(loadSelOrganisme())
        dispatch(loadLoggedUser())
    }, [])

    return (
        <>
            <AsideMenu organisme={sel_organisme} loggedUser={loggedUser} user_routes={admin_routes} onLogOut={dologout} isOpen={isOpen} setIsopen={setIsopen} />

            <div className={isOpen ? "rw-cont bg-gray" : "rw-cont rw-cont-mini bg-gray"}>

                <Routes>
                    <Route index element={<Home />} />
                    <Route path='formation' element={<Formations />} />
                    <Route path='formation/:id' element={<FormationDetails />} />
                    <Route path='pages' element={<Pages />} />
                    <Route path='pages/:id' element={<PageDetails />} />
                    <Route path='organisme' element={<Organismes />} />
                    <Route path='candidature' element={<Candidatures />} />
                    <Route path='contact-msg' element={<ContactMessages />} />
                    <Route path='users' element={<Users />} />
                </Routes>
            </div>

        </>
    );
};

export default Main;